import { useServices } from 'react-decoupler';
import SERVICE_KEYS from './service-keys';

export const useAuthService = () => useServices([SERVICE_KEYS.AUTH])[0];
export const useStaffListApiService = () =>
    useServices([SERVICE_KEYS.STAFF_LIST_API])[0];
export const useMemberApiService = () =>
    useServices([SERVICE_KEYS.MEMBER_API])[0];
export const useAddressVerificationApiService = () =>
  useServices([SERVICE_KEYS.ADDRESS_VERIFICATION_API])[0];
export const useMembershipApiService = () =>
  useServices([SERVICE_KEYS.MEMBERSHIP_API])[0];

