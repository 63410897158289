import React, {
	useState,
	useLayoutEffect,
} from 'react';
import Form, {
	SimpleItem,
	Label,
} from 'devextreme-react/form';
import List from 'devextreme-react/list';
import { useNavigate } from "react-router-dom";
import { showError, showSuccess } from '../../helpers/ui';
import { useStaffListApiService } from '../../services';
import { useGlobalState } from '../../contexts/global-state';
import { usePageTitle } from '../../contexts/page-title';
import './review-records-request.scss';

export default function ({ schoolId }) {
	const navigate = useNavigate();
	const { requestRecordReviewAsync } = useStaffListApiService();
	const [personalizedMessage, setPersonalizedMessage] = useState();
	const {
		selectedMembers,
		setSelectedMembers
	} = useGlobalState();
	const { setTitle } = usePageTitle();

	useLayoutEffect(() => setTitle("Request Record Review"), [setTitle]);

	function renderMember(item) {
		return (
			<div className="item-list">
				<h5>{item.fullName}</h5>
			</div>
		);
	}

	return (
		<div className="responsive-paddings">
			<p id="message2">Please include an optional personalized message below to send to the members with your request.</p>
			<p id="message1">By sending this message, you are requesting selected members to review their records for correctness and completion.</p>
			<Form
				id="dataForm"
			>
				<SimpleItem dataField='PersonalizedMessage' editorType='dxTextArea'
					editorOptions={
						{
							height: '8em',
							onValueChanged: (e) => {
								setPersonalizedMessage(e.value);
							}
						}
					}>
					<Label text='Personalized message to send to selected members' />
				</SimpleItem>
				<SimpleItem
					name="sendButton"
					itemType="button"
					horizontalAlignment="left"
					buttonOptions={{
						className: 'button',
						width: '100%',
						text: 'Send',
						type: 'default',
						onClick: async (e) => {
							try {
								if (typeof (selectedMembers) === 'undefined') {
									showError('No member is selected.');
									return;
								}
								e.component.option("disabled", true);
								const r = await requestRecordReviewAsync(
									schoolId,
									selectedMembers.map(a => a.memberId),
									personalizedMessage
								);
								if (r.data) {
									showSuccess('Email message has been sent.');
									if (process.env.JEST_WORKER_ID !== undefined) return;
									setSelectedMembers([]);
									navigate(-1);
								} else {
									showError('There was an error sending the request email.');
									e.component.option("disabled", false);
								}
							}
							catch (error) {
								showError(error.message);
								e.component.option("disabled", false);
							}
						}
					}} />
			</Form>
			<List
				id="memberList"
				dataSource={selectedMembers}
				showSelectionControls={false}
				searchEnabled={false}
				itemRender={renderMember}
				scrollingEnabled={false}
				useNativeScrolling={true} />
		</div>
	);
}