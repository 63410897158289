import React, { } from 'react';
import { Link } from 'react-router-dom';
import { usePageTitle } from '../../contexts/page-title';

export default () => {
  const { setTitle } = usePageTitle();
  setTitle("Epitome Member Access");
  return (
    <div className="responsive-paddings">
      You are signed out. <Link to="/">Click here to sign in again.</Link>
    </div>
  );
}
