import React, {
  useLayoutEffect,
} from 'react';
import { useNavigate } from "react-router-dom";
import Form, {
  SimpleItem,
  Label,
} from 'devextreme-react/form';
import { showError } from '../../helpers/ui';
import { useGlobalState } from '../../contexts/global-state';
import { usePageTitle } from '../../contexts/page-title';
import './staff-details.scss';

export default function ({ schoolId, memberId }) {

  const navigate = useNavigate();
  const {
    setSelectedMembers,
    selectedSchool
  } = useGlobalState();
  const { setTitle } = usePageTitle();

  useLayoutEffect(() => setTitle("Staff Details"), [setTitle]);

  let rowData = {};

  if (!memberId) {
    showError('No member is selected.');
    navigate(-1);
    return;
  } else {
    rowData = selectedSchool &&
      selectedSchool.staffList &&
      selectedSchool.staffList.find(x => x.memberId === parseInt(memberId))
  }

  return (rowData ? (
    <div className="responsive-paddings">
      <Form
        id='staffDetailsDataForm'
        formData={rowData}
        scrollingEnabled={true}
      >
        <SimpleItem dataField='preferredFirstName' editorType='dxTextBox' editorOptions={{ readOnly: true }}>
          <Label text='Preferred First Name' />
        </SimpleItem>
        <SimpleItem dataField='firstName' editorType='dxTextBox' editorOptions={{ readOnly: true }}>
          <Label text='First Name' />
        </SimpleItem>
        <SimpleItem dataField='lastName' editorType='dxTextBox' editorOptions={{ readOnly: true }}>
          <Label text='Last Name' />
        </SimpleItem>
        <SimpleItem dataField='division' editorType='dxTextBox' editorOptions={{ readOnly: true }}>
          <Label text='Division' />
        </SimpleItem>
        <SimpleItem dataField='workEmailAddress' editorType='dxTextBox' editorOptions={{ readOnly: true }}>
          <Label text='Work Email Address' />
        </SimpleItem>
        <SimpleItem
          name="requestUpdateButton"
          itemType="button"
          horizontalAlignment="center"
          buttonOptions={{
            text: 'Request Record Update',
            type:'default',
            onClick: () => {
              setSelectedMembers([rowData]);
              navigate(`../review-records-request`);
            }
          }} />
      </Form>
    </div>
  ) : <></>);
}