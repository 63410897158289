const urlEncodedContentType = 'application/x-www-form-urlencoded';
const jsonContentType = 'application/json';

export default class AddressVerificationPostGridApiService {
    constructor() {
        this.getAutoCompleteAddresses = this.getAutoCompleteAddresses.bind(this);
        this.getAddressDetails = this.getAddressDetails.bind(this);
    }

    async fetchData(url, method, body, contentType) {
        const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': contentType,
                'x-api-key': window.env.addressVerificationPostGridApiConfig.key,
            },
            body: body,
        })
        if (response.status !== 200) {
            throw new Error("Error in calling the API.");
        }
        const data = await response.json();
        return { data: data };
    }

    async getAddressData(requestUrl) {
        let response = await this.fetchData(requestUrl, 'GET', null, urlEncodedContentType);
        let addresses = response.data.data.map((n, index) => {
            return {
                place_id: index,
                description: `${n.preview.address}, ${n.preview.city}, ${n.preview.prov}`,
            };
        })
        return addresses;
    }

    async getAutoCompleteAddresses(searchTerm, city, province) {
        if ('' === searchTerm) return [];
        searchTerm = searchTerm.split(' ').join('+');
        let baseRequestUrl = `https://api.postgrid.com/v1/addver/completions?partialStreet=${searchTerm}&countryFilter=CA&provInsteadOfPC=true`;
        let requestUrl = baseRequestUrl;
        if (city !== '' && city !== null && typeof city !== 'undefined') {
            city = city.split(' ').join('+');
            requestUrl += `&cityFilter=${city}`;
        }

        if (province !== '' && province !== null && typeof province !== 'undefined') {
            province = province.split(' ').join('+');
            requestUrl += `&stateFilter=${province}`;
        }

        let addresses = await this.getAddressData(requestUrl, false);
        if (addresses.length === 0) {
            requestUrl = baseRequestUrl;
            addresses = await this.getAddressData(requestUrl, true);
        }
        return addresses;
    }

    async getAddressDetails(place_id, partialStreet, city, province) {
        let requestUrl = `https://api.postgrid.com/v1/addver/completions?index=${place_id}`;
        let body = JSON.stringify({ 'partialStreet': partialStreet, 'countryFilter': 'CA', stateFilter: province, cityFilter: city });
        let response = await this.fetchData(requestUrl, 'POST', body, jsonContentType);
        if (response.data.data.length === 0) {
            body = JSON.stringify({ 'partialStreet': partialStreet, 'countryFilter': 'CA' });
            response = await this.fetchData(requestUrl, 'POST', body, jsonContentType);
        }
        return {
            'address': response.data.data.address.address,
            'city': response.data.data.address.city,
            'postal_code': response.data.data.address.pc,
            'province': response.data.data.address.prov,
        }
    }
}