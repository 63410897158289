import moment from 'moment';

function selectDateBoxSetDate(e) {
  var txt = e.component.option('text').trim();
  let acceptedDateInputs = [
    //One-digit month and day, no year
    'M-D',
    'M/D',
    'M.D',
    'M D',
    //Two-digit month and day, no year
    'MM-DD',
    'MM/DD',
    'MM.DD',
    'MM DD',
    //One-digit month and day, two-digit year
    'M-D-YY',
    'M/D/YY',
    'M.D.YY',
    'M D YY',
    //Two-digit month and day, two-digit year
    'MM-DD-YY',
    'MM/DD/YY',
    'MM.DD.YY',
    'MM DD YY',
    //One-digit month and day, four-digit year
    'M-D-YYYY',
    'M/D/YYYY',
    'M.D.YYYY',
    'M D YYYY',
    //Two-digit month and day, four-digit year
    'MM-DD-YYYY',
    'MM/DD/YYYY',
    'MM.DD.YYYY',
    'MM DD YYYY',
    //Two-digit year, one-digit month and day
    'YY-M-D',
    'YY/M/D',
    'YY.M.D',
    'YY M D',
    //Two-digit year, two-digit month and day
    'YY-MM-DD',
    'YY/MM/DD',
    'YY.MM.DD',
    'YY MM DD',
    'YYMMDD',
    //Four-digit year, one-digit month and day
    'YY-M-D',
    'YY/M/D',
    'YY.M.D',
    'YY M D',
    //Four-digit year, two-digit month and day
    'YYYY-MM-DD',
    'YYYY/MM/DD',
    'YYYY.MM.DD',
    'YYYY MM DD',
    'YYYYMMDD',
    //One-digit day, short month name, no year
    'D-MMM',
    'D/MMM',
    'D.MMM',
    'D MMM',
    //Two-digit day, short month name, no year
    'DD-MMM',
    'DD/MMM',
    'DD.MMM',
    'DD MMM',
    //One-digit day, short month name, two-digit year
    'D-MMM-YY',
    'D/MMM/YY',
    'D.MMM.YY',
    'D MMM YY',
    //Two-digit day, short month name, two-digit year
    'DD-MMM-YY',
    'DD/MMM/YY',
    'DD.MMM.YY',
    'DD MMM YY',
    //One-digit day, short month name, four-digit year
    'D-MMM-YYYY',
    'D/MMM/YYYY',
    'D.MMM.YYYY',
    'D MMM YYYY',
    //Two-digit day, short month name, four-digit year
    'DD-MMM-YYYY',
    'DD/MMM/YYYY',
    'DD.MMM.YYYY',
    'DD MMM YYYY',
    //Short month name, one-digit day, no year
    'MMM-D',
    'MMM/D',
    'MMM.D',
    'MMM D',
    //Short month name, two-digit day, no year
    'MMM-DD',
    'MMM/DD',
    'MMM.DD',
    'MMM DD',
    //Short month name, one-digit day, two-digit year
    'MMM-D-YY',
    'MMM/D/YY',
    'MMM.D.YY',
    'MMM D YY',
    'MMM D, YY',
    //Short month name, two-digit day, two-digit year
    'MMM-DD-YY',
    'MMM/DD/YY',
    'MMM.DD.YY',
    'MMM DD YY',
    'MMM DD, YY',
    //Short month name, one-digit day, four-digit year
    'MMM-D-YYYY',
    'MMM/D/YYYY',
    'MMM.D.YYYY',
    'MMM D YYYY',
    'MMM D, YYYY',
    //Short month name, two-digit day, four-digit year
    'MMM-DD-YYYY',
    'MMM/DD/YYYY',
    'MMM.DD.YYYY',
    'MMM DD YYYY',
    'MMM DD, YYYY',
    //One-digit day, long month name, no year
    'D-MMMM',
    'D/MMMM',
    'D.MMMM',
    'D MMMM',
    //Two-digit day, long month name, no year
    'DD-MMMM',
    'DD/MMMM',
    'DD.MMMM',
    'DD MMMM',
    //One-digit day, long month name, two-digit year
    'D-MMMM-YY',
    'D/MMMM/YY',
    'D.MMMM.YY',
    'D MMMM YY',
    //Two-digit day, long month name, two-digit year
    'DD-MMMM-YY',
    'DD/MMMM/YY',
    'DD.MMMM.YY',
    'DD MMMM YY',
    //One-digit day, long month name, four-digit year
    'D-MMMM-YYYY',
    'D/MMMM/YYYY',
    'D.MMMM.YYYY',
    'D MMMM YYYY',
    //Two-digit day, long month name, four-digit year
    'DD-MMMM-YYYY',
    'DD/MMMM/YYYY',
    'DD.MMMM.YYYY',
    'DD MMMM YYYY',
    //long month name, one-digit day, no year
    'MMMM-D',
    'MMMM/D',
    'MMMM.D',
    'MMMM D',
    //long month name, two-digit day, no year
    'MMMM-DD',
    'MMMM/DD',
    'MMMM.DD',
    'MMMM DD',
    //long month name, one-digit day, two-digit year
    'MMMM-D-YY',
    'MMMM/D/YY',
    'MMMM.D.YY',
    'MMMM D YY',
    'MMMM D, YY',
    //long month name, two-digit day, two-digit year
    'MMMM-DD-YY',
    'MMMM/DD/YY',
    'MMMM.DD.YY',
    'MMMM DD YY',
    'MMMM DD, YY',
    //long month name, one-digit day, four-digit year
    'MMMM-D-YYYY',
    'MMMM/D/YYYY',
    'MMMM.D.YYYY',
    'MMMM D YYYY',
    'MMMM D, YYYY',
    //long month name, two-digit day, four-digit year
    'MMMM-DD-YYYY',
    'MMMM/DD/YYYY',
    'MMMM.DD.YYYY',
    'MMMM DD YYYY',
    'MMMM DD, YYYY',
  ]
  for (const format of acceptedDateInputs) {
    let isValid = moment(txt, format, true).isValid();
    if (isValid) {
      var date = moment(txt, format).format('YYYY-MM-DD');
      e.component.reset();
      e.component.option('value', date);
      break; //this will stop further evaluation
    }
  }
}

export const appConst = {
  dateDisplayFormat: 'yyyy-MM-dd',
  dateAndTimeDisplayFormat: 'yyyy-MM-dd hh:mm a',
  defaultSelectBoxOptions: {
    valueExpr: 'value',
    displayExpr: 'text',
    searchEnabled: true,
    searchMode: 'startswith',
    minSearchLength: 0,
    showDataBeforeSearch: true,
    searchTimeout: 200,
    showClearButton: true,
  },
  postalCodePattern: "^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$",
  postalCodeValidationMessage: "Invalid postal code.",
  emailPattern: "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
  emailPatternEpsbDomain: "^[\\w-\\.]+@([eE][pP][sS][bB].[cC][aA])$",
  emailValidationMessage: "Invalid email format.",
  emailValidationMessageEpsbDomain: "Email must be in epsb.ca",
  phonePattern: "^\\(([0-9]{3})\\)\\s([0-9]{3})-([0-9]{4})$",
  phone10DigitsPattern: "^\\d{10}$",
  phoneEditorOptions: {
    mask: '(X00) 000-0000',
    maskRules: {
      X: /[02-9]/
    },
    useMaskedValue: false,
    maskInvalidMessage: 'Invalid phone format.'
  },
  defaultSelectDateBoxOptions: {
    showClearButton: true,
    displayFormat: 'yyyy-MM-dd',
    invalidDateMessage: 'Value must be a date.',
    dateSerializationFormat: 'yyyy-MM-dd',
    onKeyDown: (e) => {
      if (e.event.key === 'Enter') {
        selectDateBoxSetDate(e);
      }
    },
    onFocusOut: (e) => {
      selectDateBoxSetDate(e);
    }
  },
  slideDuration: 150,
  defaultGridOptions: {
    remoteOperations: true,
    rowAlternationEnabled: true,
    showBorders: true,
    allowColumnReordering: true,
    allowColumnResizing: true,
    columnResizingMode: "nextColumn",
    hoverStateEnabled: true,
    wordWrapEnabled: true,
    pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [10, 20, 50, 100],
      showInfo: true,
      visible: false,
      showInGroupFooter: true
    },
    summary: {
      groupItems: [{
        column: "Id",
        summaryType: "count"
      }]
    },
    grouping: {
      allowCollapsing: true,
      autoExpandAll: false,
      contextMenuEnabled: true,
      expandMode: "rowClick"
    },
    groupPanel: {
      visible: false,
      allowColumnDragging: true
    },
    filterPanel: {
      visible: false,
    },
    filterRow: {
      visible: false,
    },
    export: {
      enabled: false,
      allowExportSelectedData: true,
    },
    onExporting: (e) => {
      e.fileName = e.element.id;
    },
    scrolling: {
      mode: "virtual",
      rowRenderingMode: "virtual",
    },
    searchPanel: {
      visible: true,
    }
  }
}

