import React from 'react';
import { useLocation } from 'react-router-dom';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import './footer.scss';

export default ({ form, scrollViewRef }) => {
  const editProfilePath = "/edit-profile";

  const { pathname } = useLocation();

  function scrollToGroupIndex(index) {
    const items = form.option("items");
    scrollViewRef.current.instance.scrollToElement(document.querySelector(`.${items[index].cssClass}`))
  }

  return (
    (pathname && pathname.includes(editProfilePath)) &&
    <footer className='footer-component'>
      <Toolbar id='footer'>
        <Item
            location="before"
        >
            <Button
              hint="Identification"
              icon="fa-solid fa-person"
              onClick={() => {
                scrollViewRef && scrollViewRef.current.instance.scrollTo(0);
              }}
            />
        </Item>
        <Item
          location="before"
        >
            <Button
              icon="fa-solid fa-envelope"
              hint="Email Addresses"
              onClick={() => {
                scrollToGroupIndex(1);
              }}
            />
        </Item>
        <Item
          location="before"
        >
            <Button
              hint="Work Locations"
              icon="fa-solid fa-location-dot"
              onClick={() => {
                scrollToGroupIndex(2);
              }}
            />
        </Item>
        <Item
          location="before"
        >
            <Button
              icon="fa-solid fa-trophy"
              hint="Designations"
              onClick={() => {
                scrollToGroupIndex(4);
              }}
            />
        </Item>
      </Toolbar>
      </footer>
  )
};
