import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStateProvider } from './contexts/global-state';
import { PageTitleProvider } from './contexts/page-title';
import { EditProfileProvider } from './contexts/edit-profile';
import { useScreenSizeClass } from './utils/media-query';
import Content from './content';

import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import './app.scss';

export default function () {
  const screenSizeClass = useScreenSizeClass();
  return (
    <Router>
      <PageTitleProvider>
        <EditProfileProvider>
        <GlobalStateProvider>
          <div className={`app ${screenSizeClass}`}>
            <Content />
          </div>
          </GlobalStateProvider>
        </EditProfileProvider>
      </PageTitleProvider>
    </Router>
  );
}
