import React, {
  useState,
  createContext,
  useContext,
  useLayoutEffect
} from 'react';
import AppInfo from '../app-info';

const PageTitleContext = createContext({});

const usePageTitle = () => useContext(PageTitleContext);

function PageTitleProvider(props) {
  const [title, setTitleInternal] = useState('');

  const setTitle = (value) => {
    if (!value)
      value = AppInfo.title;
    setTitleInternal(value);
  };

  useLayoutEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <PageTitleContext.Provider
      value={{
        setTitle,
        title
      }}
      {...props}
    />
  );
}

export {
  PageTitleProvider,
  usePageTitle
};