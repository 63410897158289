import React, {
  useLayoutEffect,
} from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useStaffListApiService } from '../../services';
import { useGlobalState } from '../../contexts/global-state';
import { showError } from '../../helpers/ui';

export default ({ schoolId }) => {
  const {
    selectedSchool,
    setSelectedSchool,
    managedSchools } = useGlobalState();
  const {
    getStaffListBySchoolAsync
  } = useStaffListApiService();

  const navigate = useNavigate();

  if (!schoolId) {
    showError("No school was specified.");
    navigate(-1);
  }

  useLayoutEffect(() => {
    if (!selectedSchool || selectedSchool.schoolId !== parseInt(schoolId)) {
      const school = managedSchools.find(s => s.schoolId === parseInt(schoolId));
      school && (getStaffListBySchoolAsync(schoolId).then(r => {
          school.staffList = r.data;
          setSelectedSchool(school);
        })
      );
    }
  }, [
    selectedSchool,
    setSelectedSchool,
    managedSchools,
    schoolId,
    getStaffListBySchoolAsync,
  ]);

  return <Outlet />;
}