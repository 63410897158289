import React, {
  useLayoutEffect,
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback,
} from 'react';
import { useAccount } from '@azure/msal-react';
import {
  useMembershipApiService,
  useStaffListApiService,
} from "../services";
import { showError } from '../helpers/ui';

const GlobalStateContext = createContext({});
const useGlobalState = () => useContext(GlobalStateContext);

function GlobalStateProvider(props) {
  const [lookupData, setLookupData] = useState({});
  const [managedSchools, setManagedSchools] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState();
  const { getLookupsAsync } = useMembershipApiService();
  const { getSchoolsForWhichUserIsACommRepAsync, getCurrentSchoolYearInfo } = useStaffListApiService();
  const [defaultSuggestedSAStartAndEndDates, setDefaultSuggestedSAStartAndEndDates] = useState();
  const user = useAccount();

  function getNearestPastJune30() {
    const now = new Date();  // Current date
    const currentYear = now.getFullYear();  // Current year

    // Create a Date object for June 30 of the current year
    let june30 = new Date(currentYear, 5, 30);  // Months are 0-indexed, so 5 is June

    // If today is before June 30 of this year, return June 30 of the previous year
    if (now < june30) {
      june30 = new Date(currentYear - 1, 5, 30);
    }

    return june30;
  }

  const handleSuggestedDefaultDates = useCallback((schoolYearInfo) => {
    let currentDate = new Date();
    let schoolStartDate = new Date(schoolYearInfo.startDate);
    let schoolEndDate = new Date(schoolYearInfo.endDate);

    let defaultStartDate;
    if (currentDate <= schoolEndDate) {
      defaultStartDate = new Date(schoolStartDate.getFullYear(), 8, 1);
    } else {
      defaultStartDate = new Date(schoolEndDate.getFullYear() + 1 , 8, 1);
    }

    let defaultEndDate = getNearestPastJune30();

    setDefaultSuggestedSAStartAndEndDates({ defaultStartDate: defaultStartDate, defaultEndDate: defaultEndDate });
  }, []);


  useLayoutEffect(() => {
    (async () => {
      if (user) {
        try {
          let r = await getSchoolsForWhichUserIsACommRepAsync();
          if (r.data) {
            setManagedSchools(r.data);
          } else {
            showError('Error getting schools for which the signed on user is a communication rep.')
          }

          r = await getCurrentSchoolYearInfo();
          if (r.data) {
            handleSuggestedDefaultDates(r.data);
          } else {
            showError('Error getting schools for which the signed on user is a communication rep.')
          }

        }
        catch (error) {
          showError(error.message);
        }
      }
    })();
  }, [
    setManagedSchools,
    getSchoolsForWhichUserIsACommRepAsync,
    user,
    getCurrentSchoolYearInfo,
    handleSuggestedDefaultDates
  ]);

  useEffect(() => {
    (async () => {
      let lookupData = {};
      const getOps = [
        getLookupsAsync('GetSalutations').then((response) => lookupData.salutations = response.data.data),
        getLookupsAsync('GetGenders').then((response) => lookupData.genders = response.data.data),
        getLookupsAsync('GetPronouns').then((response) => lookupData.pronouns = response.data.data),
        getLookupsAsync('GetEmailAddressTypes').then((response) => lookupData.emailAddressTypes = response.data.data),
        getLookupsAsync('GetPhoneNumberTypes').then((response) => lookupData.phoneNumberTypes = response.data.data),
        getLookupsAsync('GetSchools').then((response) => lookupData.schools = response.data.data),
        getLookupsAsync('GetDivisions').then((response) => lookupData.divisions = response.data.data),
        getLookupsAsync('GetDesignations').then((response) => lookupData.designations = response.data.data),
        getLookupsAsync('GetProvinces').then((response) => lookupData.provinces = response.data.data),
      ];
      await Promise.all(getOps);
      setLookupData(lookupData);
    })();
  }, [
    getLookupsAsync,
    setLookupData
  ]);

  return (
    <GlobalStateContext.Provider
      value={{
        lookupData,
        setLookupData,
        managedSchools,
        setManagedSchools,
        selectedSchool,
        setSelectedSchool,
        selectedMembers,
        setSelectedMembers,
        defaultSuggestedSAStartAndEndDates,
      }}
      {...props}
    />
  );
}

export {
  GlobalStateProvider,
  useGlobalState,
};

