import React from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import { usePageTitle } from './contexts/page-title';
import { useEditProfile } from './contexts/edit-profile';
import {
  AuthOutlet,
  PassRouteParams,
  SelectedSchoolRoute,
} from './components';
import { SingleCard } from './layouts';
import {
  HomePage,
  ReviewRecordsRequest,
  StaffList,
  StaffDetails,
  AddStaffRequest,
  EditProfile,
  LocateMembership,
  RequestMembership,
  SignedOut
} from './pages';

export default function () {
  const { title } = usePageTitle();
  const { editProfileInfo } = useEditProfile();

  return (
    <Routes>
      <Route element={<SingleCard title={title} editProfileInfo={editProfileInfo} />}>
        <Route element={<AuthOutlet />}>
          <Route index element={<HomePage />} />
          <Route path="/edit-profile/:memberId" element={<PassRouteParams><EditProfile /></PassRouteParams>} />
          <Route path="/school/:schoolId" element={<PassRouteParams><SelectedSchoolRoute /></PassRouteParams>}>
            <Route index element={<PassRouteParams><StaffList /></PassRouteParams>} />
            <Route path=":memberId" element={<PassRouteParams><StaffDetails /></PassRouteParams>} />
            <Route path="review-records-request" element={<PassRouteParams><ReviewRecordsRequest /></PassRouteParams>} />
            <Route path="add-staff-request" element={<PassRouteParams><AddStaffRequest /></PassRouteParams>} />
          </Route>
        </Route>
        <Route path="/locate-membership/:username/:count" element={<PassRouteParams><LocateMembership /></PassRouteParams>} />
        <Route path="/request-membership/:username" element={<PassRouteParams><RequestMembership /></PassRouteParams>} />
        <Route path="*" element={<p>Page not found!</p>} />
        <Route path="/signed-out" element={<SignedOut />} />
      </Route>
    </Routes>
  );
}