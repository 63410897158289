import { ServiceLocator } from 'react-decoupler';
import SERVICE_KEYS from './services/service-keys';
import AuthService from './services/auth-service-msal';
import StaffListApiService from './services/staff-list-api-service';
import MemberApiService from './services/member-api-service';
import AddressVerificationPostGridApiService from './services/address-verification-postgrid-api-service';
import MembershipApiService from './services/membership-api-service';

const serviceLocator = new ServiceLocator();

const authService = new AuthService();

serviceLocator.register(SERVICE_KEYS.AUTH, authService);
serviceLocator.register(SERVICE_KEYS.STAFF_LIST_API, new StaffListApiService(authService));
serviceLocator.register(SERVICE_KEYS.MEMBER_API, new MemberApiService(authService));
serviceLocator.register(SERVICE_KEYS.ADDRESS_VERIFICATION_API, new AddressVerificationPostGridApiService(authService));
serviceLocator.register(SERVICE_KEYS.MEMBERSHIP_API, new MembershipApiService(authService));

export default serviceLocator;
