import React from 'react';
import notify from 'devextreme/ui/notify';
import { Button as TextBoxButton } from 'devextreme-react/text-box';

export function showError(message) {
  notify(message, 'error', 4000);
}

export function showSuccess(message) {
    notify(message, 'success', 2000);
}

export function renderVerificationIcon(isValid) {
  const imgUrl = isValid ? '../../imgs/valid.svg' : '../../imgs/invalid.svg';
  return (
    <TextBoxButton
      id='txtbtn'
      name='validityIcon'
      location='after'
      options={{
        icon: imgUrl,
        type: 'default',
      }}
    />
  )
}

function iSAddressVerified(data, storedAddress) {
  if (storedAddress.addressVerified) {
    if (data.city.toUpperCase() === storedAddress.city.toUpperCase()
      && data.provinceId === storedAddress.provinceId
      && data.postalCode.toUpperCase() === storedAddress.postalCode.toUpperCase()
      && data.address1.toUpperCase() === storedAddress.address1.toUpperCase()
    ) {
      return true
    } else {
      return false
    }
  } else {
    return false;
  }
}

export function compareAddresses(e, data, storedAddress, setAddressVerified) {
  if (e.component.NAME === "dxTextBox") {
    const val = e.value ?? "";
    e.component.option("value", val.toUpperCase());
  }
  if (typeof e.event !== 'undefined') {
    if (iSAddressVerified(data, storedAddress)) {
      setAddressVerified(true);
    } else {
      setAddressVerified(false);
    }
  }
}

export function getStoredAddress(data) {
  const obj = {
    addressVerified: data.addressVerified,
    city: data.city && data.city.toUpperCase(),
    provinceId: data.provinceId,
    postalCode: data.postalCode && data.postalCode.toUpperCase(),
    address1: data.address1 && data.address1.toUpperCase(),
  }
  return obj;
}

export function createStoredAddress(addressObj) {
  const obj = {
    addressVerified: true,
    city: addressObj.city,
    provinceId: addressObj.provinceId,
    postalCode: addressObj.postalCode,
    address1: addressObj.address,
  }
  return obj;
}

export function updateStateAddressFields(addressObj, currentState, provinces) {
  const province = provinces && provinces.find(p => p.text === addressObj.province);
  addressObj.provinceId = province && province.value;

  const newState = { ...currentState };
  newState.address1 = addressObj.address;
  newState.city = addressObj.city;
  newState.provinceId = addressObj.provinceId;
  newState.postalCode = addressObj.postalCode;
  newState.addressVerified = true;
  return newState;
}