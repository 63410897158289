import { createStore } from "devextreme-aspnet-data-nojquery";
import { urlEncodedContentType, jsonContentType, fetchData } from './fetch-data';


const {
  staffListApiConfig: { url },
  defaultScopes: scopes,
  timeZone
} = window.env;

export default class StaffListApiService {
  getAuthHeaderAsync = async () => { };
  constructor({ getAuthorizationHeaderAsync }) {
    if (!getAuthorizationHeaderAsync)
      throw Error(
        "An authorization service must be specified for the protected graph API."
      );
    this.getAuthHeaderAsync = getAuthorizationHeaderAsync;
    this.getSchoolsForWhichUserIsACommRepAsync = this.getSchoolsForWhichUserIsACommRepAsync.bind(this);
    this.getStaffListBySchoolAsync = this.getStaffListBySchoolAsync.bind(this);
    this.requestRecordReviewAsync = this.requestRecordReviewAsync.bind(this);
    this.requestSchoolAssociationRemovalAsync = this.requestSchoolAssociationRemovalAsync.bind(this);
    this.updateStaffListCurrentDateAsync = this.updateStaffListCurrentDateAsync.bind(this);
    this.findMemberByNameAsync = this.findMemberByNameAsync.bind(this);
    this.requestSchoolAssociationAdditionAsync = this.requestSchoolAssociationAdditionAsync.bind(this);
    this.getCurrentSchoolYearInfo = this.getCurrentSchoolYearInfo.bind(this);
  }

  async getSchoolsForWhichUserIsACommRepAsync() {
    const params = {
      timeZone: window.env.timeZone,
    };
    const fetchUrl = `${url}/GetSchoolsForWhichUserIsACommRep?${new URLSearchParams(params)}`;
    const response = await fetchData(fetchUrl, this.getAuthHeaderAsync, scopes, 'GET', urlEncodedContentType);
    return response;
  }

  async getStaffListBySchoolAsync(schoolId) {
    const params = {
      schoolId,
      timeZone 
    };
    const fetchUrl = `${url}/GetStaffListBySchool?${new URLSearchParams(params)}`;
    return await fetchData(fetchUrl, this.getAuthHeaderAsync, scopes, 'GET', urlEncodedContentType);
  }

  async getCurrentSchoolYearInfo() {
    const fetchUrl = `${url}/GetCurrentSchoolYearInfo`;
    return await fetchData(fetchUrl, this.getAuthHeaderAsync, scopes, 'GET', urlEncodedContentType);
  }

  findMemberByNameAsync() {
    return createStore({
      key: "Id",
      loadUrl: `${url}/FindMemberByName`,
      onBeforeSend: async (_, ajaxOptions) => {
        const authHeaderValue = await this.getAuthHeaderAsync(scopes);
        if (authHeaderValue) {
          if (!ajaxOptions.headers) {
            ajaxOptions.headers = {};
          }
          Object.assign(ajaxOptions.headers, {
            Authorization: authHeaderValue,
          });
        }
      },
    });
  }

  async requestRecordReviewAsync(schoolId, memberIds, message) {
    const body = JSON.stringify({ 'SchoolId': schoolId, 'MemberIds': memberIds, 'Message': message });
    const fetchUrl = `${url}/RequestRecordReview`
    const response = await fetchData(fetchUrl, this.getAuthHeaderAsync, scopes, 'POST', jsonContentType, body);
    return response;
  }

  async requestSchoolAssociationRemovalAsync(schoolId, memberIds, message) {
    const body = JSON.stringify({ 'SchoolId': schoolId, 'MemberSchoolInfo': memberIds, 'Message': message });
    const fetchUrl = `${url}/RequestSchoolAssociationRemoval`
    const response = await fetchData(fetchUrl, this.getAuthHeaderAsync, scopes, 'POST', jsonContentType, body);
    return response;
  }

  async updateStaffListCurrentDateAsync(schoolId) {
    const params = {
      schoolId: schoolId,
    };
    const fetchUrl = `${url}/UpdateStaffListCurrentDate?${new URLSearchParams(params)}`;
    const response = await fetchData(fetchUrl, this.getAuthHeaderAsync, scopes, 'POST', urlEncodedContentType);
    return response;
  }

  async requestSchoolAssociationAdditionAsync(schoolId, memberIds, emailAddresses) {
    const body = JSON.stringify({ 'SchoolId': schoolId, 'MemberSchoolInfo': memberIds, 'EmailAddresses': emailAddresses });
    const fetchUrl = `${url}/RequestSchoolAssociationAddition`;
    const response = await fetchData(fetchUrl, this.getAuthHeaderAsync, scopes, 'POST', jsonContentType, body);
    return response;
  }
}