import React, {
  useLayoutEffect,
} from 'react';
import LoadPanel from 'devextreme-react/load-panel';
import Button from 'devextreme-react/button';
import { useNavigate } from "react-router-dom";
import { useGlobalState } from '../../contexts/global-state';
import { usePageTitle } from '../../contexts/page-title';
import {
  useAuthService,
} from "../../services";

import '../../components/component.scss';
import './home.scss';

export default function () {
  const { managedSchools } = useGlobalState();
  const navigate = useNavigate();
  const user = useAuthService().getActiveAccount();
  const { idTokenClaims: { givenName, memberId } } = user || { idTokenClaims: {} }
  const { setTitle } = usePageTitle();

  useLayoutEffect(() => setTitle("Epitome Member Access"), [setTitle]);

  return (user ? (
    <div className="responsive-paddings">
      <div className="title">
        <p id="welcomeMessage">Welcome To Epitome Member Access, {givenName}</p>
      </div>
      <div>
        <Button
          text="My Member Profile" id="myProfile"
          onClick={
            async () => {
              navigate(`/edit-profile/${memberId}`);
            }
          }
        />
      </div>
      <div style={{ display: 'inline-block' }}>
        {(managedSchools && managedSchools.length > 0) && <h5> Staff Lists You Manage</h5>}
        {managedSchools && managedSchools.map((school) => {
          const {
            schoolId,
            name
          } = school;
          return <Button
            id={`school${schoolId}`}
            key={schoolId}
            text={name}
            onClick={
              () => {
                navigate(`/school/${schoolId}`);
              }
            }
          />
        })
        }
      </div>
    </div>
  ) : <LoadPanel />);
}
