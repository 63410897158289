export const urlEncodedContentType = 'application/x-www-form-urlencoded';
export const jsonContentType = 'application/json';

export async function fetchData(url, getAuthHeaderAsync, scopes, method, contentType, body, ApiKey) {
  let response;
  if (!ApiKey) {
    const authHeaderValue = await getAuthHeaderAsync(scopes);
    response = await fetch(url, {
      method: method,
      headers: new Headers({
        'Content-Type': contentType,
        Authorization: authHeaderValue,
        'Accept': 'application/json'
      }),
      body: body,
    })
  } else {
    response = await fetch(url, {
      method: method,
      headers: new Headers({
        'Content-Type': contentType,
        'ApiKey': ApiKey,
        'Accept': 'application/json'
      }),
      body: body,
    })
  }
  const data = await response.json();
  if (response.ok) {
      return { data: data };
  } else {
      throw new Error(`Status:${response.status} ${data}`)
  }
}