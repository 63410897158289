import React, {
  useState,
  useLayoutEffect,
} from 'react';
// eslint-disable-next-line no-unused-vars
import TextArea from 'devextreme-react/text-area';
import Form, {
  ButtonItem,
  SimpleItem,
  Label,
  RequiredRule,
  PatternRule,
} from 'devextreme-react/form';
import { useGlobalState } from '../../contexts/global-state';
import { usePageTitle } from '../../contexts/page-title';
import { appConst } from '../../app-const';
import { useMembershipApiService } from "../../services";
import { showError } from '../../helpers/ui';
import '../../components/component.scss';

export default function ({ count, username }) {
  const {
    sendRequestToLocateMembershipAsync } = useMembershipApiService();
  const [emailSent, setEmailSent] = useState(false);
  const [formData] = useState({
    UserName: username,
    MemberCount: count
  });
  const [submitBtnText, setSubmitBtnText] = useState("Submit");
  const { setTitle } = usePageTitle();
  const { lookupData } = useGlobalState();

  const { schools } = lookupData;
  const schoolEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    items: schools,
  };

  useLayoutEffect(() => setTitle("Locate Membership"), [setTitle]);

  const submitButtonOptions = {
    disabled: (submitBtnText === 'Submitting...'),
    text: submitBtnText,
    type: 'default',
    useSubmitBehavior: 'true'
  }

  const otherInformationEditorOptions = {
    height: '8em'
  };

  const handleSubmit = async (e) => {
    e.preventDefault && e.preventDefault();
    try {
      setSubmitBtnText('Submitting...');
      const result = await sendRequestToLocateMembershipAsync(formData);
      if (result.data) {
        setEmailSent(true);
      } else {
        showError('Error sending request to locate membership information.')
      }
      setSubmitBtnText('Submit');
    } catch (error) {
      showError(error.message);
      setSubmitBtnText('Submit');
    }
  }

  const renderUsernameMessage = () => <p>You signed in as {username}, so please try to provide an alternate email or phone number below to help us locate your record.</p>

  function renderFillOutInformation() {
    if (!emailSent) {
      return (
        <>
          <div className='title'>
            {(parseInt(count) === 0) &&
              <p id="enterInformationMessage1">Please provide some identifying information and our staff will try to locate your membership record.</p>
            }
            {(parseInt(count) > 1) &&
              <p id="enterInformationMessage2">Please provide some identifying information and our staff will try to resolve the multiple membership record situation.</p>
            }
          </div>
          <form onSubmit={handleSubmit}>
            <Form
              id='dataForm'
              formData={formData}
              showValidationSummary={true}
              scrollingEnabled={false}
            >
              <SimpleItem dataField='FirstName' editorType='dxTextBox'>
                <Label text='First Name' />
                <RequiredRule message='First Name is Required' />
              </SimpleItem>
              <SimpleItem dataField='LastName' editorType='dxTextBox'>
                <Label text='Last Name' />
                <RequiredRule message='Last Name is Required' />
              </SimpleItem>
              <SimpleItem dataField='PreviousLastName' editorType='dxTextBox'>
                <Label text='Previous Last Name(s)' />
              </SimpleItem>
              <SimpleItem
                render={renderUsernameMessage}
              />
              <SimpleItem dataField='WorkEmailAddress' editorType='dxTextBox'>
                <Label text='Email' />
                <RequiredRule message=" Email is Required" />
                <PatternRule
                  pattern={appConst.emailPattern}
                  message={appConst.emailValidationMessage} />
              </SimpleItem>
              <SimpleItem dataField='PhoneNumber' editorType='dxTextBox' editorOptions={appConst.phoneEditorOptions}>
                <Label text='Phone Number' />
                <RequiredRule message="Phone Number is Required" />
                <PatternRule
                  pattern={appConst.phone10DigitsPattern}
                  message={appConst.phoneEditorOptions.message}
                />
              </SimpleItem>
              <SimpleItem dataField='DateOfBirth' editorType='dxDateBox'
                editorOptions={appConst.defaultSelectDateBoxOptions}
              >
                <Label text='Date of Birth' />
                <RequiredRule message="Date of Birth is Required" />
              </SimpleItem>
              <SimpleItem dataField='TeachingCertificate' editorType='dxTextBox'>
                <Label text='Teaching Certificate Number (if known)' />
              </SimpleItem>
              <SimpleItem editorType="dxSelectBox" dataField="SchoolDecisionUnit"
                editorOptions={schoolEditorOptions}>
                <Label text="School / Decision Unit Where You Currently Work" />
                <RequiredRule message="School is Required" />
              </SimpleItem>
              <SimpleItem dataField='OtherInformation' editorType='dxTextArea' editorOptions={otherInformationEditorOptions}>
                <Label text='Any Other Information to Help Us Resolve This Issue' />
              </SimpleItem>
              <ButtonItem
                name="submitButton"
                itemType="button"
                horizontalAlignment="center"
                buttonOptions={submitButtonOptions}
              />
              </Form>
          </form>
        </>
      );
    } else {
      return <></>
    }
  }

  function renderInformationPosted() {
    if (emailSent) {
      return (
          <>
            <div className='title'>
              Thanks!
            </div>
            <p id="submitMessage">Our staff will attempt to resolve your sign-in problem using the info you provided. Someone will reach out to you by email or phone when they have completed the review.</p>
          </>);
    } else {
      return <></>
    }
  }

  return (
    <div className="responsive-paddings">
      {renderFillOutInformation()}
      {renderInformationPosted()}
    </div>
  );
}
