import React from 'react';
import ReactDOM from 'react-dom';
import { EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { DecouplerProvider } from 'react-decoupler';
import serviceLocator from './configure-services';
import SERVICE_KEYS from './services/service-keys';
import App from './app';
import reportWebVitals from './report-web-vitals';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import './polyfills';
import './index.css';


const pca = serviceLocator.resolve([SERVICE_KEYS.AUTH])[0].getPublicClientApplicationInstance();

pca.enableAccountStorageEvents();
pca.addEventCallback((message) => {
  if (message.eventType === EventType.ACCOUNT_ADDED) {
    const account = message.payload;
    pca.setActiveAccount(account);
  } else if (message.eventType === EventType.ACCOUNT_REMOVED) {
    pca.setActiveAccount(null);
  }
});

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <DecouplerProvider locator={serviceLocator}>
        <App />
      </DecouplerProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
