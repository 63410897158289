import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
} from 'react';
import { useNavigate } from "react-router-dom";
/* eslint-disable no-unused-vars */
import TextArea from 'devextreme-react/text-area';
import Switch from 'devextreme-react/switch';
/* eslint-enable no-unused-vars */
import Form, {
  SimpleItem,
  Label,
  PatternRule,
  RequiredRule,
  GroupItem,
  EmptyItem,
  ColCountByScreen,
  Item,
} from 'devextreme-react/form';
import DxForm from "devextreme/ui/form";
import { LoadPanel } from 'devextreme-react/load-panel';
import { confirm } from 'devextreme/ui/dialog';
import { AddressAutoComplete, CardContainer } from '../../components';
import { useMemberApiService } from '../../services';
import {
  showError,
  showSuccess,
  getStoredAddress,
  createStoredAddress,
  compareAddresses,
  updateStateAddressFields,
} from '../../helpers/ui';
import { useScreenSize } from '../../utils/media-query';
import { appConst } from '../../app-const';
import { useGlobalState } from '../../contexts/global-state';
import { usePageTitle } from '../../contexts/page-title';
import { useEditProfile } from '../../contexts/edit-profile';

import './edit-profile.scss';

export default function ({ memberId }) {
  const {
    getByIdAsync,
    updateByIdAsync
  } = useMemberApiService();
  const [member, setMember] = useState();
  const [fetchingData, setFetchingData] = useState(true);
  const [city, setCity] = useState();
  const [province, setProvince] = useState();
  const { isLarge, isSmall, isXSmall } = useScreenSize();
  const { lookupData } = useGlobalState();
  const [storedAddress, setStoredAddress] = useState();
  const navigate = useNavigate();
  const { setTitle } = usePageTitle();
  const { setEditProfileInfo} = useEditProfile();

  const salutationEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    items: lookupData.salutations,
  };

  const genderEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    items: lookupData.genders
  };

  const pronounEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    items: lookupData.pronouns,
  };

  const provinceEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    items: lookupData.provinces,
    onValueChanged: (e) => {
      setProvince(e.component.option("text"));
      compareAddresses(e, member, storedAddress, setAddressVerified);
    }
  }

  const phoneNumberTypeEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    items: lookupData.phoneNumberTypes,
    grouped: false,
  };

  const emailAddressTypeEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    items: lookupData.emailAddressTypes,
    grouped: false,
  };

  const designationEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    grouped: true,
    items: lookupData.designations,
  };

  useLayoutEffect(() => setTitle("Edit Profile"), [setTitle]);

  const handleFetchMember = useCallback(async () => {
    setFetchingData(true);
    const { data } = await getByIdAsync(memberId);
    if (data) {
      setMember(data);
      setStoredAddress(getStoredAddress(data));
      setFetchingData(false);
    }
  }, [getByIdAsync, memberId]);

  const undo = async () => {
    const result = confirm("<i>Are you sure to undo all the changes?</i>", "Confirm changes");
    result.then(async (dialogResult) => {
      if (!dialogResult) {
      } else {
        await handleFetchMember();
      }
    });
  }


  useEffect(() => {
    (async () => {
      try {
        if (memberId) {
          await handleFetchMember();
          return;
        }
        throw new Error("Your member profile could not be loaded.");
      }
      catch (error) {
        showError(error.message);
        setFetchingData(false);
        navigate(-1);
      }
    })();
  }, [getByIdAsync, memberId, navigate, handleFetchMember]);

  async function saveProfile() {
    let form = getFormInstance("memberForm");
    let res = form.validate();
    let isValid = res.isValid;

    if (!isValid) {
      res.brokenRules[0].validator.focus();
      return false;
    }

    const result = confirm("<i>Are you sure you have reviewed ALL of your information?</i>", "Confirm changes");
    result.then(async (dialogResult) => {
      if (!dialogResult) {
      } else {
        try {
          const addressNameAndVerfication = addressAutoCompleteRef.current.getAddress();

          let data = {
            firstName: member.firstName,
            lastName: member.lastName,
            preferredFirstName: member.preferredFirstName,
            previousLastName: member.previousLastName,
            address1: addressNameAndVerfication.address,
            address2: member.address2,
            city: member.city,
            provinceId: member.provinceId,
            postalCode: member.postalCode,
            pronounsId: member.pronounsId,
            salutationId: member.salutationId,
            emailAddresses: member.emailAddresses,
            phoneNumbers: member.phoneNumbers,
            birthDate: member.birthDate,
            certificateNumber: member.certificateNumber,
            startDate: member.startDate,
            genderId: member.genderId,
            addressVerified: addressNameAndVerfication.addressVerified,
            schools: member.schools.concat(member.previousSchools),
            designations: member.designations,
          }
          const response = await updateByIdAsync(member.id, data);
          if (response.data) {
            showSuccess('Your profile has been updated.')
            navigate(-1);
          } else {
            showError('An error occurred while saving your profile.');
          }
        } catch (error) {
          setFetchingData(false);
          showError(error.message);
        }
      }
    });
  }

  function setPreferredEmailOrPhone(index, property) {
    if (member[property].length === 0) return;
    let tmp = { ...member };
    if (tmp[property][index].preferred) {
      for (var i = 0; i < tmp[property].length; i++) {
        if (i !== index)
          tmp[property][i].preferred = false;
      }
      setMemberAndKeepScrollPosition(tmp);
    }
  }

  function getFormInstance(id) {
    let formEl = document.getElementById(id);
    return DxForm.getInstance(formEl);
  }

  function updateAddressFields(addressObj) {
    const { provinces } = lookupData;
    setMember(updateStateAddressFields(addressObj, member, provinces));
    setStoredAddress(createStoredAddress(addressObj));
  }

  function setAddressVerified(value) {
    setMember({ ...member, addressVerified: value });
  }

  const addressAutoCompleteRef = useRef();

  function setMemberAndKeepScrollPosition(member) {
    const scrollTop = document.getElementsByClassName('dx-scrollable-container')[0].scrollTop;
    setMember(member);
    document.getElementsByClassName('dx-scrollable-container')[0].scrollTop = scrollTop;
  }

  function updatePrimaryToFalse(index, endDate, selectedSAToBePrimary) {
    //This function is used to end a school association and
    //it is used to to set a new main location if the parameter selectedSAToBePrimary
    //is passed.
    let temp = { ...member };
    const schools = member.schools;
    const previousSchools = member.previousSchools;

    if (selectedSAToBePrimary!==null) {
      if (typeof temp.schools[selectedSAToBePrimary] !== 'undefined') {
        schools[selectedSAToBePrimary].primary = true;
      }
    }

    let el;
    if (index > -1) {
      el = schools[index];
      el.primary = false;
      el.endDate = endDate;
      previousSchools.push(el);
      schools.splice(index, 1);
      temp.previousSchools = previousSchools;
    }

    temp.schools = schools;
    setMember(temp);
  }

  function updatePrinaryToTrue(index) {
    //this function is used to set a main location
    let temp = { ...member };

    if (index > -1) {
      temp.schools[index].primary = true;
      temp.schools.forEach((school, i) => {
        if (i !== index) {
          school.primary = false;
        }
      });
      setMember(temp);
    }
  }

  function updateSADivision(index, divisionId) {
    if (index > -1) {
      member.schools[index].divisionId = divisionId;
    }
  }

  function updateSAStartDate(index, startDate) {
    if (index > -1) {
      member.schools[index].startDate = startDate;
    }
  }

  function addNewSA(sa) {
    //this function is used to add a new school association and
    //to end a location if the object has the two properties saIndexToEnd and selectedEndDate
    const b = Object.hasOwn(sa, 'saIndexToEnd') && Object.hasOwn(sa, 'selectedEndDate');
    if (b) {
      updatePrimaryToFalse(sa.saIndexToEnd, sa.selectedEndDate)
    }
    let temp = { ...member };
    if (sa.primary) {
      temp.schools.forEach((school, i) => {
        school.primary = false;
      });
    }
    const newSa = {
      schoolId: sa.schoolId,
      startDate: sa.startDate,
      divisionId: sa.divisionId,
      endDate:null,
      primary: sa.primary,
      school: { id: sa.schoolId, name: sa.schoolName }
    }
    temp.schools.push(newSa);
    setMember(temp);
  }

  const saveButtonName = "doneButton";

  return (
    <div className="responsive-paddings" id="profile-member-wrapper">
      <Form
        id='memberForm'
        formData={member}
        showValidationSummary={true}
        height="100%"
        className={(isSmall || isXSmall) ? "member-form-small" : "member-form"}
        onOptionChanged={(e) => {
          if (member) {
            if (!e.component.isNotFirstLoad) {
              e.component.isNotFirstLoad = true;
              setEditProfileInfo({
                recordReviewedByMember: member.recordReviewedByMember,
                form: e.component,
                saveButtonId: saveButtonName,
                undo: undo,
              })
            }
          }
        }}
      >
        <GroupItem caption="Identification">
          <ColCountByScreen xs={1} sm={1} md={2} lg={2} />
          <SimpleItem dataField='firstName' editorType='dxTextBox'>
            <Label text='First Name' />
            <RequiredRule message='First Name is required.' />
          </SimpleItem>
          <SimpleItem dataField='lastName' editorType='dxTextBox'>
            <Label text='Last Name' />
            <RequiredRule message='Last Name is required.' />
          </SimpleItem>
          <SimpleItem dataField='preferredFirstName' editorType='dxTextBox'>
            <Label text='Preferred First Name' />
          </SimpleItem>
          <SimpleItem dataField='previousLastName' editorType='dxTextBox'>
            <Label text='Previous Last Name' />
          </SimpleItem>
          <SimpleItem
            dataField='salutationId'
            editorType='dxSelectBox'
            editorOptions={salutationEditorOptions}
          >
            <Label text='Salutation' />
          </SimpleItem>
          <SimpleItem
            dataField='genderId'
            editorType='dxSelectBox'
            editorOptions={genderEditorOptions}
            visible={false}
          >
            <Label text='Gender' />
          </SimpleItem>
          <SimpleItem
            dataField='pronounsId'
            editorType='dxSelectBox'
            editorOptions={pronounEditorOptions}
            visible={true}
          >
            <Label text='Pronouns' />
          </SimpleItem>
          <SimpleItem
            dataField='birthDate'
            editorType='dxDateBox'
            editorOptions={appConst.defaultSelectDateBoxOptions}
          >
            <Label text='Date of Birth (YYYY-MM-DD)' />
          </SimpleItem>
          <Item colSpan={1} dataField="address1">
            <Label text='Home Mailing Address' />
            <AddressAutoComplete
              autoCompleteTextBoxId={"autoCompleteTextBoxId"}
              updateAddressFields={updateAddressFields}
              value={member && member.address1}
              city={city}
              province={province}
              addressVerified={member && member.addressVerified}
              ref={addressAutoCompleteRef}
            />
          </Item>
          <EmptyItem visible={!isLarge} />
          <SimpleItem dataField='city' editorType='dxTextBox' colSpan={1} editorOptions={{
            onValueChanged: (e) => {
              setCity(e.value);
              compareAddresses(e, member, storedAddress, setAddressVerified)
            }
          }}>
            <Label text='City' />
          </SimpleItem>
          <SimpleItem
            dataField='provinceId'
            editorType='dxSelectBox'
            editorOptions={provinceEditorOptions}
          >
            <Label text='Province' />
          </SimpleItem>
          <SimpleItem dataField='postalCode' editorType='dxTextBox'
            editorOptions={{
              onValueChanged: (e) => {
                compareAddresses(e, member, storedAddress, setAddressVerified)
              }
            }}
          >
            <Label text='Postal Code' />
            <PatternRule
              pattern={appConst.postalCodePattern}
              message={appConst.postalCodeValidationMessage}
            />
          </SimpleItem>
          <SimpleItem dataField="certificateNumber" editorType="dxTextBox">
            <Label text='Teaching Certificate Number' />
          </SimpleItem>
          <EmptyItem />
          <EmptyItem />
        </GroupItem>
        <GroupItem cssClass="emails_phones">
          <ColCountByScreen xs={1} sm={1} md={2} lg={2} />
          <GroupItem key={`emails`} caption={'Email Addresses'}>
            {member && member.emailAddresses && member.emailAddresses.map((email, index) => (
              <GroupItem key={`emailGroup${index}`}>
                <ColCountByScreen xs={1} sm={1} md={2} lg={2} />
                <SimpleItem
                  key={`email${email.id}`}
                  dataField={`emailAddresses[${index}].address`}
                >
                  <Label text={`Email ${index + 1}`} visible={false} />
                  <RequiredRule message="Email is required." />
                  <PatternRule
                    pattern={appConst.emailPattern}
                    message={appConst.emailValidationMessage}
                  />
                </SimpleItem>
               
                <GroupItem>
                  <ColCountByScreen xs={6} sm={6} md={6} lg={6} />
                  <SimpleItem
                    key={`emailTypes${index}`}
                    colSpan={3}
                    editorType="dxSelectBox"
                    dataField={`emailAddresses[${index}].typeId`}
                    editorOptions={emailAddressTypeEditorOptions}
                  >
                    <Label text={`Email Type ${index + 1}`} visible={false} />
                  </SimpleItem>
                  <SimpleItem
                    colSpan={2}
                    key={`preferredEmails${email.Id}`}
                    dataField={`emailAddresses[${index}].preferred`}
                    editorType="dxSwitch"
                    editorOptions={{
                      onValueChanged: (e) => {
                        if (typeof (e.event) !== 'undefined') {
                          setPreferredEmailOrPhone(index, 'emailAddresses');
                        }
                      }
                    }}
                  >
                    <Label text="Preferred" location="left" />
                  </SimpleItem>
                  <SimpleItem
                    colSpan={1}
                    name={`removeEmailButton${index}`}
                    itemType="button"
                    horizontalAlignment="left"
                    buttonOptions={{
                      icon: 'trash',
                      hint: 'Remove email.',
                      onClick: () => {
                        if (email.preferred) {
                          showError("You can't delete a preferred email.")
                          return false;
                        }
                        let tmp = { ...member };
                        tmp.emailAddresses.splice(index, 1);
                        setMemberAndKeepScrollPosition(tmp);
                      }
                    }}>
                  </SimpleItem>
                </GroupItem>
              </GroupItem>
            ))}
            <SimpleItem
              name="addEmailButton"
              itemType="button"
              horizontalAlignment="center"
              buttonOptions={{
                icon: 'add',
                text: 'Add Email',
                onClick: () => {
                  let tmp = { ...member };
                  tmp.emailAddresses.push({ address: '', preferred: (member.emailAddresses && !member.emailAddresses.length) });
                  setMember(tmp);
                  let form = getFormInstance('memberForm');
                  let emailEditor = form.getEditor(`emailAddresses[${tmp.emailAddresses.length - 1}].address`);
                  if (typeof (emailEditor) !== 'undefined')
                    emailEditor.focus();
                },
              }} />
          </GroupItem>
          <GroupItem key={`phones`} colCount={1} caption={'Phone Numbers'}>
            {member && member.phoneNumbers && member.phoneNumbers.map((phone, index) => (
              <GroupItem key={`phoneGroup${index}`}>
                <ColCountByScreen xs={1} sm={1} md={2} lg={2} />
                <SimpleItem
                  key={`phone${phone.id}`}
                  dataField={`phoneNumbers[${index}].number`}
                  editorOptions={appConst.phoneEditorOptions}
                >
                  <Label text={`Phone ${index + 1}`} visible={false} />
                  <RequiredRule message="Phone is required." />
                  <PatternRule
                    pattern={appConst.phone10DigitsPattern}
                    message={appConst.phoneEditorOptions.message}
                  />
                </SimpleItem>
                <GroupItem>
                  <ColCountByScreen xs={6} sm={6} md={6} lg={6} />
                <SimpleItem
                  key={`phoneTypes${index}`}
                  colSpan={3}
                  editorType="dxSelectBox"
                  dataField={`phoneNumbers[${index}].typeId`}
                  editorOptions={phoneNumberTypeEditorOptions}
                >
                  <Label text={`Phone Type ${index + 1}`} visible={false} />
                </SimpleItem>
                <SimpleItem
                    colSpan={2}
                    key={`preferredPhone${phone.Id}`}
                    dataField={`phoneNumbers[${index}].preferred`}
                    editorType="dxSwitch"
                    verticalAlignment="top"
                    editorOptions={{
                      onValueChanged: (e) => {
                        if (typeof (e.event) !== 'undefined') {
                          setPreferredEmailOrPhone(index, 'phoneNumbers');
                        }
                      }
                    }}
                  >
                    <Label text="Preferred" location="left" />
                  </SimpleItem>
                  <SimpleItem
                    colCount={1}
                    name={`removePhoneButton${index}`}
                    itemType="button"
                    horizontalAlignment="right"
                    verticalAlignment="bottom"
                    buttonOptions={{
                      icon: 'trash',
                      hint: 'Remove Phone.',
                      onClick: () => {
                        if (phone.preferred) {
                          showError("You can't delete a preferred phone.")
                          return false;
                        }
                        let tmp = { ...member };
                        tmp.phoneNumbers.splice(index, 1);
                        setMemberAndKeepScrollPosition(tmp);
                      }
                    }}>
                  </SimpleItem>
                </GroupItem>
              </GroupItem>
            ))}
            <SimpleItem
              name="addPhoneButton"
              itemType="button"
              horizontalAlignment="center"
              buttonOptions={{
                icon: 'add',
                text: 'Add Phone',
                onClick: () => {
                  let tmp = { ...member };
                  tmp.phoneNumbers.push({ address: '', preferred: (member.phoneNumbers && !member.phoneNumbers.length) });
                  setMember(tmp);
                  let form = getFormInstance('memberForm');
                  let phoneEditor = form.getEditor(`phoneNumbers[${tmp.phoneNumbers.length - 1}].number`);
                  if (typeof (phoneEditor) !== 'undefined')
                    phoneEditor.focus();
                },
              }}
            />
          </GroupItem>
        </GroupItem>
        <GroupItem cssClass="school_associations">
          <Item>
            <h3>Current Work Location(s)</h3>
          </Item>
          <Item render={() => {
            return (
              <CardContainer
                data={member && member.schools}
                updatePrinaryToFalse={updatePrimaryToFalse}
                updateSADivision={updateSADivision}
                updateSAStartDate={updateSAStartDate}
                updatePrinaryToTrue={updatePrinaryToTrue}
                addNewSA={addNewSA}
                userHasWorkLocation={member && member.schools.length > 0}
                showAddNewLocationButton={true}
              />
            )
          }}>
          </Item>
        </GroupItem>
        <GroupItem>
          <Item>
            <h3>Previous Work Locations</h3>
            <h4>If you have returned to work at one of these locations, please click “Add Another Work Location +” and indicate the date you returned to that location.</h4>
          </Item>
          <Item render={() => { return (<CardContainer data={member && member.previousSchools} showAddNewLocationButton={false} />) }}>
          </Item>
        </GroupItem>
        <GroupItem caption="Designations" colSpan={2} cssClass="designations" visible={member && member.designations && member.designations.length>0}>
          {member && member.designations && member.designations.map((designation, index) => (
            <GroupItem key={`designation${index}`}>
              <ColCountByScreen xs={1} sm={1} md={5} lg={5} />
              <SimpleItem
                key={`designation.designation.description${index}`}
                dataField={`designations[${index}].designationId`}
                editorType="dxSelectBox"
                editorOptions={designationEditorOptions}
                valueExpr='value'
                displayExpr='text'
                selectByClick={true}
                selectionMode='single'
              >
                <Label text="Designation" />
                <RequiredRule message='Designation is required.' />
              </SimpleItem>
              <SimpleItem
                key={`designation.startDate${index}`}
                editorType="dxDateBox"
                dataField={`designations[${index}].startDate`}
                editorOptions={{ displayFormat: appConst.dateDisplayFormat }}>
                <RequiredRule message='Start date is required.' />
                <Label text="Start Date (YYYY-MM-DD)" />
              </SimpleItem>
              <SimpleItem
                key={`designation.endDate${index}`}
                editorType="dxDateBox"
                dataField={`designations[${index}].endDate`}
                editorOptions={{ displayFormat: appConst.dateDisplayFormat }} >
                <Label text="End Date (YYYY-MM-DD)" />
              </SimpleItem>
              <SimpleItem
                key={`designation.notes${index}`}
                editorType="dxTextBox"
                dataField={`designations[${index}].notes`}>
                <Label text="Notes" />
              </SimpleItem>
              <SimpleItem
                name={`removeDesignationButton${index}`}
                itemType="button"
                horizontalAlignment="left"
                verticalAlignment="bottom"
                buttonOptions={{
                  icon: 'trash',
                  hint: 'Remove Designation',
                  onClick: () => {
                    let tmp = { ...member };
                    tmp.designations.splice(index, 1);
                    setMemberAndKeepScrollPosition(tmp);
                  }
                }}>
              </SimpleItem>
            </GroupItem>
          ))}
        </GroupItem>
        <GroupItem colSpan={2}>
          <SimpleItem
            name="addDesignationButton"
            itemType="button"
            horizontalAlignment="center"
            buttonOptions={{
              text: 'New Designation',
              icon: 'add',
              onClick: () => {
                let tmp = { ...member };
                tmp.designations.push({});
                setMember(tmp);
                let form = getFormInstance('memberForm');
                let emailEditor = form.getEditor(`designations[${tmp.designations.length - 1}].designationId`);
                if (typeof (emailEditor) !== 'undefined')
                  emailEditor.focus();
              },
            }}
          />
        </GroupItem>
        <SimpleItem colSpan={2}
          name={saveButtonName}
          itemType="button"
          width="100%"
          horizontalAlignment="center"
          buttonOptions={{
            text: "My Info is Correct and Complete",
            icon: "check",
            type: "default",
            onClick: async (e) => await saveProfile(),
          }}
        />
      </Form>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={fetchingData}
        showIndicator={true}
        shading={true}
        showPane={true}
        closeOnOutsideClick={false}
      />
    </div>
  )
}