import React, {
  useState,
  createContext,
  useContext,
} from 'react';

const EditProfileContext = createContext({});

const useEditProfile = () => useContext(EditProfileContext);

function EditProfileProvider(props) {
  const [editProfileInfo, setEditProfileInfoInternal] = useState({});

  const setEditProfileInfo = (value) => {
    setEditProfileInfoInternal(value);
  };

  return (
    <EditProfileContext.Provider
      value={{
        setEditProfileInfo,
        editProfileInfo,
      }}
      {...props}
    />
  );
}

export {
  EditProfileProvider,
  useEditProfile
};