import React from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'devextreme-react/button';

export default function (props) {
  const { path, state, ...otherProps } = props;
  const navigate = useNavigate();
  return (
    <Button
      {...otherProps}
      icon='back'
      onClick={
        () => {
          if (path)
            navigate(path, { state });
          else
            navigate(-1);
        }
      }
    />
  );
}