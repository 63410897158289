import React, { useState } from 'react';
import DateBox from 'devextreme-react/date-box';
import SelectBox from 'devextreme-react/select-box';
import Button from 'devextreme-react/button';
import RadioGroup from 'devextreme-react/radio-group';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { Popup } from 'devextreme-react/popup';
import { useGlobalState } from '../../../contexts/global-state';
import { appConst } from '../../../app-const';
import styles from './card.module.scss';

export function Card(props) {
  const { data, containerId, updatePrimaryToFalse, updateSADivision, updateSAStartDate, updatePrinaryToTrue, schools, showAddNewSAPopupVisible } = props;
  const { index, school, primary, startDate, endDate, divisionId } = data;
  const [endDatePopupVisible, setEndDatePopupVisible] = useState(false);
  const [selectedEndDate, setSelectedEndDate] = useState(new Date().toDateString());
  const [makeMainPopupVisible, setMakeMainPopupVisible] = useState(false);
  const [availableSchools, setAvailableSchools] = useState([]);
  const [selectedSAIndex, setSelectedSAIndex] = useState(null);
  const [okButonCaption, setOKButtonCaption] = useState('Ok');
  const { lookupData } = useGlobalState();
  const readOnly = (endDate !== null);

  function updateSelectedEndDate(value) {
    setSelectedEndDate(value);
  }

  function hideEndDatePopup(e) {
    setEndDatePopupVisible(false);
    setSelectedEndDate(new Date().toDateString());
    setSelectedSAIndex(null);
  }

  function setEndDate() {
    //If the user does not select a new main location and there are other
    //locations available, error is shown
    if (selectedSAIndex===null && (availableSchools.length-1) > 0 && primary) {
      notify('Please select your main work location or add a new one.', 'error');
      return;
    }
    if (!selectedEndDate) {
      notify('Please select an end date.', 'error');
      return;
    }
    const fn = () => {
      hideEndDatePopup();
      updatePrimaryToFalse(index, selectedEndDate, selectedSAIndex);
    }
    if (selectedSAIndex !== null) {
      //Display a confirmation dialog that a new main location has been selected.
      const schoolIndex = availableSchools.findIndex(x => x.value === selectedSAIndex);
      const result = confirm(`<i>If you change your main work location to ${availableSchools[schoolIndex].text}, this will become the location at which you receive member communications.</i>`, "Confirm changes");
      result.then(async (dialogResult) => {
        if (dialogResult) {
          fn();
        }
      });
    } else {
      fn();
    }
  }

function updateMain() {
  hideMakeMainPopup();
  updatePrinaryToTrue(index)
}

function hideMakeMainPopup(e) {
  setMakeMainPopupVisible(false);
}

function setEndDateContent() {
  const showSelectNewLocation = primary && availableSchools && availableSchools.length > 0;
  return (
    <div className={styles['end-date-popup-content']}>
      <div className={styles['end-date-popup-body']}>
        On what date did you stop working at {school.name}?
        <DateBox
          value={selectedEndDate}
          type='date'
          label='End Date'
          onValueChanged={(e) => {
            updateSelectedEndDate(e.value);
          }}
          {...appConst.defaultSelectDateBoxOptions}
        />
        {showSelectNewLocation && <span>Select your new main location</span>}
        <RadioGroup
          value={selectedSAIndex}
          visible={showSelectNewLocation}
          dataSource={availableSchools}
          valueExpr='value'
          displayExpr='text'
          label='Select your new main location'
          onValueChanged={(e) => {
            setSelectedSAIndex(e.value);
            if (-1 === e.value) {
              setOKButtonCaption("Next");
            } else {
              setOKButtonCaption("Ok");
            }
          }}
        />
      </div>
      <div className={styles['end-date-popup-footer']}>
        <Button text={okButonCaption}
          type='default'
          onClick={(e) => {
            if ('Ok' === okButonCaption) {
              setEndDate();
            };
            if ('Next' === okButonCaption) {
              hideEndDatePopup();
              showAddNewSAPopupVisible(index, selectedEndDate);
            }
          }}
          width='50%'
        />
        <Button
          text='Cancel'
          onClick={() => {
            hideEndDatePopup();
          }}
          width='50%'
          type='default'
        />
      </div>
    </div>
  );
}

function makeMainContent() {
  return (
    <div className={styles['make-main-popup-content']}>
      <div className={styles['make-main-popup-body']}>
        <span>
          If you change your main work location to {school.name}, this will become the location at which you receive member communications.
        </span>
      </div>
      <div className={styles['make-main-popup-footer']}>
        <Button text='Ok' onClick={updateMain} width="50%" type='default' />
        <Button text='Cancel' onClick={hideMakeMainPopup} width="50%" type='default' />
      </div>
    </div>
  );
}

return (
  <div className={styles.card} data-testid='card'>
    <div className={styles['card-header']} data-testid='card-header'>
      <h1>{school.name}</h1>
      {primary && <h2>Main Location</h2>}
    </div>
    <div className={styles['card-body']} data-testid='card-body'>
      {startDate && (
        <div className={styles['start-date']} data-testid='start-date'>
          <DateBox
            defaultValue={startDate}
            type='date' readOnly={readOnly}
            label='Start Date'
            displayFormat='yyyy-MM-dd'
            onValueChanged={(e) => {
              updateSAStartDate(index, e.value);
            }}
          />
        </div>
      )}
      {endDate && (
        <div className={styles['end-date']} data-testid='end-date'>
          <DateBox defaultValue={endDate} type='date' readOnly={readOnly} label='End Date' displayFormat='yyyy-MM-dd' />
        </div>
      )}
      <div className={styles['division']} data-testid='division'>
        <SelectBox
          dataSource={lookupData.divisions}
          defaultValue={divisionId}
          valueExpr='value'
          displayExpr='text'
          selectByClick={true}
          selectionMode='single'
          readOnly={readOnly}
          label='Division'
          onValueChanged={(e) => {
            updateSADivision(index, e.value);
          }}
        />
      </div>
      {!endDate && (
        <div className={styles['end-position-button']} data-testid='end-position-button'>
          <Button text="Don't Work Here Anymore?" stylingMode="text" type="success"
            onClick={(e) => {
              setEndDatePopupVisible(true);
            }}
          >
          </Button>
        </div>
      )}
      {!primary && !endDate && (
        <div className={styles['make-main-button']} data-testid='make-main-button'>
          <Button text="Make Main Location" stylingMode="text" type="success"
            onClick={(e) => {
              setMakeMainPopupVisible(true);
            }}
          >
          </Button>
        </div>
      )}
    </div>
    <Popup
      id='end-date-popup'
      visible={endDatePopupVisible}
      onHiding={hideEndDatePopup}
      dragEnabled={false}
      hideOnOutsideClick={false}
      showCloseButton={false}
      showTitle={false}
      container={containerId}
      contentRender={setEndDateContent}
      wrapperAttr={{ className: styles['end-date-popup-wrapper'] }}
      maxWidth={'400px'}
      height={'auto'}
      onShown={() => {
        let temp = [];
        schools && schools.map((sa, index) => {
          if (sa.index !== data.index) {
            temp.push({ text: sa.school.name, value: sa.index });
          }
          return index;
        });
        temp.push({ text: 'Other', value: -1 });
        setAvailableSchools(temp);
      }}
    ></Popup>
    <Popup
      id='make-main-popup'
      visible={makeMainPopupVisible}
      onHiding={hideMakeMainPopup}
      dragEnabled={false}
      hideOnOutsideClick={false}
      showCloseButton={false}
      showTitle={true}
      title={`Make ${school.name} Your Main Location`}
      container={containerId}
      contentRender={makeMainContent}
      wrapperAttr={{ className: styles['make-main-popup-wrapper'] }}
      maxWidth={'800px'}
      height={'auto'}></Popup>
  </div>
);
}
