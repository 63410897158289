import { urlEncodedContentType, jsonContentType, fetchData } from './fetch-data';

const {
  memberApiConfig: { url },
  defaultScopes: scopes,
  timeZone,
} = window.env;

export default class MemberApiService {
  getAuthHeaderAsync = async () => { };

  constructor({ getAuthorizationHeaderAsync }) {
    if (!getAuthorizationHeaderAsync)
      throw Error(
        "An authorization service must be specified for the protected graph API."
      )
    this.getAuthHeaderAsync = getAuthorizationHeaderAsync;

    this.getByIdAsync = this.getByIdAsync.bind(this);
    this.requestEditSchoolAssociationAsync = this.requestEditSchoolAssociationAsync.bind(this);
    this.requestAddSchoolAssociationAsync = this.requestAddSchoolAssociationAsync.bind(this);
    this.requestEditDesignationAsync = this.requestEditDesignationAsync.bind(this);
    this.requestAddDesignationAsync = this.requestAddDesignationAsync.bind(this);
    this.updateByIdAsync = this.updateByIdAsync.bind(this);
  }

  async getByIdAsync(memberId) {
    const params = {
      memberId: memberId,
      timeZone: timeZone,
    };
    const fetchUrl = `${url}/GetById?${new URLSearchParams(params)}`;
    return await fetchData(fetchUrl, this.getAuthHeaderAsync, scopes, 'GET', urlEncodedContentType);
  }

  async requestEditSchoolAssociationAsync(memberId, data) {
    const params = {
      memberId: memberId
    };
    const body = JSON.stringify(data);
    const fetchUrl = `${url}/RequestEditSchoolAssociation?${new URLSearchParams(params)}`;
    const response = await fetchData(fetchUrl, this.getAuthHeaderAsync, scopes, 'POST', jsonContentType, body);
    return response;
  }

  async requestAddSchoolAssociationAsync(memberId, data) {
    const params = {
      memberId: memberId
    };
    const body = JSON.stringify(data);
    const fetchUrl = `${url}/RequestAddSchoolAssociation?${new URLSearchParams(params)}`;
    const response = await fetchData(fetchUrl, this.getAuthHeaderAsync, scopes, 'POST', jsonContentType, body);
    return response;
  }

  async requestEditDesignationAsync(memberId, data) {
    const params = {
      memberId: memberId
    };
    const body = JSON.stringify(data);
    const fetchUrl = `${url}/RequestEditDesignation?${new URLSearchParams(params)}`;
    const response = await fetchData(fetchUrl, this.getAuthHeaderAsync, scopes, 'POST', jsonContentType, body);
    return response;
  }

  async requestAddDesignationAsync(memberId, data) {
    const params = {
      memberId: memberId
    };
    const body = JSON.stringify(data);
    const fetchUrl = `${url}/RequestAddDesignation?${new URLSearchParams(params)}`;
    const response = await fetchData(fetchUrl, this.getAuthHeaderAsync, scopes, 'POST', jsonContentType, body);
    return response;
  }

  async updateByIdAsync(memberId, data) {
    const params = {
      memberId: memberId,
      timeZone: window.env.timeZone,
    };
    const body = JSON.stringify(data);
    const fetchUrl = `${url}/UpdateById?${new URLSearchParams(params)}`;
    const response = await fetchData(fetchUrl, this.getAuthHeaderAsync, scopes, 'POST', jsonContentType, body);
    return response;
  }
}