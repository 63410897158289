//import { createStore } from "devextreme-aspnet-data-nojquery";
import {
  jsonContentType,
  urlEncodedContentType,
  fetchData
} from './fetch-data';


const {
  membershipApiConfig: { url },
  defaultScopes: scopes,
  lookupUrl,
  ApiKey
} = window.env;

export default class MembershipApiService {
  getAuthHeaderAsync = async () => { };
  constructor({ getAuthorizationHeaderAsync }) {
    if (!getAuthorizationHeaderAsync)
      throw Error(
        "An authorization service must be specified for the protected graph API."
      );

    this.getAuthHeaderAsync = getAuthorizationHeaderAsync;
    this.sendRequestToLocateMembershipAsync = this.sendRequestToLocateMembershipAsync.bind(this);
    this.getLookupsAsync = this.getLookupsAsync.bind(this);
    this.requestMembershipRecordAsync = this.requestMembershipRecordAsync.bind(this);
  }

  async sendRequestToLocateMembershipAsync(submittedInfo) {
    let body = JSON.stringify(submittedInfo);
    return await fetchData(`${url}/SendRequestToLocateMembership`, this.getAuthHeaderAsync, scopes, "POST", jsonContentType, body, ApiKey);
  }

  async requestMembershipRecordAsync(submittedInfo) {
    let body = JSON.stringify(submittedInfo);
    return await fetchData(`${url}/RequestMembershipRecord`, this.getAuthHeaderAsync, scopes, "POST", jsonContentType, body, ApiKey);
  }

  async getLookupsAsync(method, useApiKey) {
    return await fetchData(`${lookupUrl}/${method}`, this.getAuthHeaderAsync, scopes, "GET", urlEncodedContentType, undefined, ApiKey);
  //  return createStore({
  //    key: "value",
  //    loadUrl: `${lookupUrl}/${method}`,
  //    onBeforeSend: async (_, ajaxOptions) => {
  //      if (!ajaxOptions.headers) {
  //        ajaxOptions.headers = {};
  //      }
  //      if (!useApiKey) {
  //        const authHeaderValue = await this.getAuthHeaderAsync(scopes);
  //        if (authHeaderValue) {
  //          Object.assign(ajaxOptions.headers, {
  //            Authorization: authHeaderValue,
  //          });
  //        }
  //      } else {
  //        Object.assign(ajaxOptions.headers, {
  //          "ApiKey": ApiKey,
  //        })
  //      }
  //    },
  //  });
  }
}