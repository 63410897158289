import React from 'react';
import {
  Logger,
  LogLevel,
  PublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { Outlet } from 'react-router-dom';
import appInfo from '../app-info';

const INTERACTION_TYPE = InteractionType.Redirect;

export default class MsalAuthService {
  async getAuthorizationHeaderAsync(scopes) {
    const request = {
      account: msalApp.getActiveAccount(),
      scopes
    };

    try {
      const { accessToken } = await msalApp.acquireTokenSilent(request);
      if (accessToken) {
        return `Bearer ${accessToken}`;
      }
    }
    catch (error) {
      //We can't fall back to interactive token acquisition here.
      //See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/FAQ.md#what-can-i-do-outside-of-azuremsal-react-context
      console.error('Non-interactive error:', error);
    }
    return null;
  };

  getActiveAccount = () => {
    //Set the active account if one is already signed in or when one does.
    if (!msalApp.getActiveAccount() && msalApp.getAllAccounts().length > 0) {
      msalApp.setActiveAccount(msalApp.getAllAccounts()[0]);
    }

    return msalApp.getActiveAccount();
  }

  getAuthorizedOutletElement = () => {
    const authRequest = {
      scopes: window.env.defaultScopes
    }

    return (
      <MsalAuthenticationTemplate interactionType={INTERACTION_TYPE} authenticationRequest={authRequest} errorComponent={this.ErrorComponent} loadingComponent={this.LoadingComponent} >
        <Outlet />
      </MsalAuthenticationTemplate>
    );
  }

  ErrorComponent = ({ error }) => {
    return <div>
      <p>An Error Occurred:</p>
      <p>Error Code: {error.errorCode}</p>
      <p>Error Message: {error.errorMessage.replace(/(\r\n|\n\r|\r|\n)/g, '  ')}</p>
    </div>;
  }

  LoadingComponent = () => {
    return <p>Signing in.  Please wait...</p>;
  }

  signOut = () => {
    if (InteractionType.Popup === INTERACTION_TYPE) {
      msalApp.logoutPopup();
    } else {
      msalApp.logoutRedirect();
    }
  };

  //This method is not part of the "interface" for an auth service.
  //It's necessary to facilitate the singleton instance of the PublicClientApplication
  //to be used by this service and the MsalProvider.
  getPublicClientApplicationInstance = () => msalApp;
}

const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ') > -1;
  const msie11 = ua.indexOf('Trident/') > -1;

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  const isEdge = ua.indexOf('Edge/') > -1;

  return msie || msie11 || isEdge;
};

const { oAuthConfig } = window.env;

const msalApp = new PublicClientApplication({
  auth: oAuthConfig,
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE(),
  },
  system: {
    iframeHashTimeout: 10000,
    navigateFrameWait: 500,
    logger: new Logger(
      (_logLevel, message) => {
        console.log(message);
      },
      {
        level: LogLevel.Verbose,
        piiLoggingEnabled: true,
      },
    ),
    telemetry: {
      applicationName: appInfo.title,
      applicationVersion: appInfo.version,
      telemetryEmitter: (events) => {
        console.log('Telemetry Events:', events);
      },
    },
  },
});