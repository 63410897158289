import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import {
  useAuthService
} from "../../services";
import {
  BackButton,
} from '../';
import './header.scss';
//{ title, recordReviewedByMember, form, saveButtonId }
export default ({ title, editProfileInfo }) => {
  const signedOutPath = "/signed-out";
  const editProfilePath = "/edit-profile";

  const { pathname } = useLocation();
  const { signOut } = useAuthService();
  const navigate = useNavigate();
  const backButtonVisible = pathname !== "/" &&
    pathname !== signedOutPath;
  const signOutVisible = pathname !== signedOutPath;
  return (
    <>
      <header className="header-component">
        <Toolbar className="header-toolbar">
          <Item
            location="before"
            visible={backButtonVisible}
          >
            <BackButton
              height="30px"
              width="30px"
            />
          </Item>
          <Item
            location="before"
            cssClass="header-title"
            visible={!!title}
          >
            <div id="headerTitle">
              {title}
            </div>
          </Item>

          <Item
            location="after"
            visible={(pathname && pathname.includes(editProfilePath))}
          >
            <Button text="Undo" type="default" icon="fa-solid fa-undo" onClick={async () => {
              await editProfileInfo.undo();
            }} />
          </Item>
          <Item
            location="after"
            visible={(pathname && pathname.includes(editProfilePath))}
          >
            <Button text="Save" type="default" icon="check" onClick={async () => {
              const btn = editProfileInfo.form.getButton(editProfileInfo.saveButtonId);
              await btn._clickAction();
            }} />
          </Item>
          <Item
            location="after"
          >
            {signOutVisible ? <Button icon="fa-solid fa-right-from-bracket" onClick={async () => { navigate("/signed-out"); signOut(); }} /> : <></>}
          </Item>
        </Toolbar>
      </header>
      <header style={{ display: (title && (title === "Edit Profile")) ? 'inline-block' : "none" }}>
        <p style={{ fontWeight: "bold", textAlign: "right", paddingRight: "25px" }}>{`Last Review On: ${(editProfileInfo.recordReviewedByMember === null) ? "" : editProfileInfo.recordReviewedByMember}`}</p>
      </header>
    </>
  )
};
