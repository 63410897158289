import React, {useRef } from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import { Outlet } from 'react-router-dom';
import {
  Header, Footer
} from '../../components';
import './single-card.scss';

export default function ({ title, editProfileInfo }) {
  const scrollViewRef = useRef(null);

  return (
    <div className="single-card">
      <Header
        className="layout-header"
        menuToggleEnabled
        toggleMenu={false}
        title={title}
        editProfileInfo={editProfileInfo}
      />
      <ScrollView
        id="singleCardScrollView"
        useNative={true}
        height="100%"
        width="100%"
        ref={scrollViewRef}
      >
        <div className="content">
          <Outlet />
        </div>
      </ScrollView>
      <Footer
        form={editProfileInfo.form}
        scrollViewRef={scrollViewRef}
      />
    </div>);
}
