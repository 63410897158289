import React, {
  useState,
  useLayoutEffect,
} from 'react';
import { useNavigate } from "react-router-dom";
import { Autocomplete } from 'devextreme-react/autocomplete';
import {
  DataGrid,
  Column,
  Editing,
} from 'devextreme-react/data-grid';
import DxGrid from "devextreme/ui/data_grid";
import LoadPanel from 'devextreme-react/load-panel';
import { TextBox as TB, Button as TextBoxButton } from 'devextreme-react/text-box';
import TextBox from "devextreme/ui/text_box";
import Button from 'devextreme-react/button';
import {
  Validator,
  PatternRule
} from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import { appConst } from '../../app-const';
import { useStaffListApiService } from '../../services';
import { useGlobalState } from '../../contexts/global-state';
import { usePageTitle } from '../../contexts/page-title';
import '../../components/component.scss';
import './add-staff-request.scss';
import { showError, showSuccess } from '../../helpers/ui';

export default function () {
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [showLoadingPanel, setShowLoadingPanel] = useState(false);
  const { findMemberByNameAsync, requestSchoolAssociationAdditionAsync, getStaffListBySchoolAsync } = useStaffListApiService();
  const { selectedSchool, defaultSuggestedSAStartAndEndDates, managedSchools, setSelectedSchool } = useGlobalState();
  const { setTitle } = usePageTitle();
  const navigate = useNavigate();

  useLayoutEffect(() => setTitle("Add Staff"), [setTitle]);

  function autoCompleteItemRender(item) {
    return (
      <div className="item-container">
        <div>{item.fullName}</div>
        <div className="push-to-right">{item.primarySchool}</div>
      </div>
    );
  }

  return (
    <div className="responsive-paddings">
      <p id="message1">{"Enter the name of the member that you want to send the sign-up link to, one per line:"}</p>
      <Autocomplete
        id="memberAutoComplete"
        dataSource={findMemberByNameAsync()}
        valueExpr="fullName"
        searchExpr={["PreferredFirstName", "FirstName", "LastName"]}
        maxItemCount={250}
        placeholder="Type Name..."
        itemRender={autoCompleteItemRender}
        onSelectionChanged={(e) => {
          if (null !== e.selectedItem) {
            let tmp = [...selectedMembers];
            let item = { ...e.selectedItem };
            item.startDate = defaultSuggestedSAStartAndEndDates.defaultStartDate;
            tmp.push(item);
            setSelectedMembers(tmp);
            e.component.reset();
          }
        }}
      >
      </Autocomplete>
      <p id="message2">{"If you can't find a member by searching above (for example, if the staff member hasn't worked in Edmonton Public before), you can type their email address below."}</p>
      <ValidationGroup>
        <TB
          id="emailTextBox"
        >
          <TextBoxButton
            name="addEmail"
            location="after"
            options={
              {
                icon: 'add',
                type: 'default',
                onClick: (e) => {
                  let el = document.getElementById("emailTextBox");
                  let tb = TextBox.getInstance(el);
                  let emailTxt = tb.option('value');
                  if ('' === emailTxt || !tb.option("isValid")) return;
                  tb.reset();

                  let tmp = [...selectedMembers];
                  const email = {
                    emailAddress: emailTxt
                  }
                  tmp.push(email);
                  setSelectedMembers(tmp);
                }
              }
            }
          />
          <Validator>
            <PatternRule
              pattern={appConst.emailPattern}
              message={appConst.emailValidationMessage}
            />
          </Validator>
        </TB>
      </ValidationGroup>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={showLoadingPanel}
      />
      <div style={{ marginTop:'25px' }}>
        <DataGrid
        id="processGrid"
        dataSource={selectedMembers}
        columnHidingEnabled={true}
        onEditorPreparing={
          (e) => {
            if (e.row.rowType === 'data' && e.row.data.hasOwnProperty(e.dataField)) {
              e.editorOptions.disabled = false;
            } else {
              e.editorOptions.disabled = true;
            }
          }
        }
      >
        <Editing
          allowAdding={false}
          allowDeleting={true}
          allowUpdating={true}
        />
        <Column
          dataField="fullName"
          allowEditing={false}
        />
        <Column
          dataField="startDate"
          dataType="date"
          format={appConst.dateDisplayFormat}
        />
        <Column
          dataField="emailAddress"
          allowUpdating={false}
        />
        </DataGrid>
      </div>
      <Button
        className="send-button"
        id="send"
        type="default"
        text="Process"
        onClick={
          async (e) => {
            if (selectedMembers.length === 0) {
              showError('There is no data to process.');
              return;
            }
            let el = document.getElementById("processGrid");
            let grd = DxGrid.getInstance(el);
            grd.saveEditData();

            setShowLoadingPanel(true);
            let members = selectedMembers.filter(function (e) {
              if (e.hasOwnProperty('fullName')) {
                return e;
              } else {
                return null;
              }
            }).map(a => ({ Id: a.id, StartDate: a.startDate }));
            let emails = selectedMembers.filter(function (e) {
              if (e.hasOwnProperty('emailAddress')) {
                return e;
              } else {
                return null;
              }
            }).map(a => a.emailAddress);
            try {
              e.component.option("disabled", true);
              const r = await requestSchoolAssociationAdditionAsync(selectedSchool.schoolId, members, emails);
              if (r.data) {
                showSuccess('Email has been sent.')
                const school = managedSchools.find(s => s.schoolId === parseInt(selectedSchool.schoolId));
                school && (getStaffListBySchoolAsync(selectedSchool.schoolId).then(r => {
                  school.staffList = r.data;
                  setSelectedSchool(school);
                  navigate(-1);
                }));
              } else {
                showError('There was an error sending the data.')
              }
              e.component.option("disabled", false);
              setShowLoadingPanel(false);
            } catch (error) {
              showError(error.message)
              e.component.option("disabled", false);
              setShowLoadingPanel(false);
            }
          }
        }
      />
    </div>
  );
}