import React, {
  useState,
  useLayoutEffect,
} from 'react';

// eslint-disable-next-line no-unused-vars
import '../../components/component.scss';
import Form, {
  SimpleItem,
  Label,
  RequiredRule,
  PatternRule,
  GroupItem,
  Item,
  EmptyItem,
} from 'devextreme-react/form';
import ScrollView from 'devextreme/ui/scroll_view';
import { useGlobalState } from '../../contexts/global-state';
import { usePageTitle } from '../../contexts/page-title';
import './request-membership.scss';
import { useMembershipApiService } from "../../services";
import { appConst } from '../../app-const';
import { showError } from '../../helpers/ui';
import { AddressAutoComplete } from '../../components/'

export default function ({ username }) {
  const {
    requestMembershipRecordAsync,
  } = useMembershipApiService();
  const [emailSent, setEmailSent] = useState(false);
  const [formData, setFormData] = useState({
    userName: username,
    Schools: [{
      SchoolId: null,
      DivisionId: null
    }]
  });

  const [submitBtnText, setSubmitBtnText] = useState('Submit');
  const [city, setCity] = useState();
  const [province, setProvince] = useState();
  const { setTitle } = usePageTitle();
  const { lookupData } = useGlobalState();

  useLayoutEffect(() => setTitle("Request Membership"), [setTitle]);

  function updateAddressFields(addressObj) {
    setFormData({
      ...formData,
      HomeMailingAddress: addressObj.address,
      City: addressObj.city,
      PostalCode: addressObj.postalCode,
      Province: addressObj.province,
    })
  }

  const salutationEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    items: lookupData.salutations,
  };

  const genderEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    items: lookupData.genders
  };

  const pronounEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    items: lookupData.pronouns,
  };

  const provinceEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    items: lookupData.provinces,
    onValueChanged: (e) => setProvince(e.value)
  };

  const phoneNumberTypeEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    items: lookupData.phoneNumberTypes,
  };

  const schoolEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    items: lookupData.schools,
  };

  const divisionEditorOptions = {
    ...appConst.defaultSelectBoxOptions,
    items: lookupData.divisions,
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setSubmitBtnText('Submitting...');
      const result = await requestMembershipRecordAsync(formData);
      if (result.data) {
        setEmailSent(true);
      } else {
        showError('Error in sending request new membership information.')
      }
      setSubmitBtnText('Submit');
    } catch (error) {
      showError('Error in sending request new membership information.')
      setSubmitBtnText('Submit');
    }
  };

  function renderFillOutInformation() {
    if (!emailSent) {
      return (
        <>
          <div className='title'>
            <p id="enterInformationMessage">Welcome to the teaching profession and Edmonton Public Teachers Local 37 of the Alberta Teachers&apos; Association!</p>
          </div>
          <div>
            <p>As a new member, your assistance in keeping the information on our database current is appreciated.  All information is confidential and used only by the Local in conducting its regular business.</p>
          </div>
          <form onSubmit={handleSubmit}>
            <Form
              id='requestMembershipDataForm'
              showValidationSummary={true}
              formData={formData}
              scrollingEnabled={false}
            >
              <GroupItem colCount={1}>
                <SimpleItem>
                  <div className='title'>
                    <p>Personal Information</p>
                  </div>
                </SimpleItem>
                <SimpleItem
                  dataField='Salutation'
                  editorType='dxSelectBox'
                  editorOptions={salutationEditorOptions}
                >
                  <Label text='Salutation' />
                </SimpleItem>
                <SimpleItem dataField='FullName' editorType='dxTextBox'>
                  <Label text='Full Given Name (first and middle, if any)' />
                  <RequiredRule message="Full name is required" />
                </SimpleItem>
                <SimpleItem dataField='PreferredFirstName' editorType='dxTextBox'>
                  <Label text='Preferred First Name (if different from above)' />
                </SimpleItem>
                <SimpleItem dataField='Surname' editorType='dxTextBox'>
                  <Label text='Surname' />
                  <RequiredRule message="Surname is required" />
                </SimpleItem>
                <SimpleItem dataField='PreviousSurname' editorType='dxTextBox'>
                  <Label text='Previous Surname (if applicable)' />
                </SimpleItem>
                <SimpleItem
                  dataField='Gender'
                  editorType='dxSelectBox'
                  editorOptions={genderEditorOptions}
                  visible={false}
                >
                  <Label text='Gender' />
                </SimpleItem>
                <SimpleItem
                  dataField='Pronouns'
                  editorType='dxSelectBox'
                  editorOptions={pronounEditorOptions}
                  visible={false}
                >
                  <Label text='Preferred Pronouns' />
                </SimpleItem>
                <SimpleItem
                  dataField='DateOfBirth'
                  editorType='dxDateBox'
                  editorOptions={{ ...appConst.defaultSelectDateBoxOptions }}
                >
                  <Label text='Date of Birth' />
                </SimpleItem>
                <SimpleItem>
                  <div className='title'>
                    <p>Contact Information</p>
                  </div>
                </SimpleItem>
                <Item>
                  <Label text='Home Mailing Address' />
                  <AddressAutoComplete
                    autoCompleteTextBoxId={"autoCompleteTextBoxId"}
                    updateAddressFields={updateAddressFields}
                    value={formData && formData.HomeMailingAddress}
                    onValueChanged={(value) => {
                      setFormData({
                        ...formData,
                        HomeMailingAddress: value,
                      })
                    }}
                    city={city}
                    province={province}
                  />
                </Item>
                <EmptyItem />
                <SimpleItem dataField='City' editorType='dxTextBox' style={{ marginTop: '100px' }} editorOptions={{ onValueChanged: (e) => setCity(e.value) }}>
                  <Label text='City' />
                </SimpleItem>
                <SimpleItem
                  dataField='Province'
                  editorType='dxSelectBox'
                  editorOptions={provinceEditorOptions}
                >
                  <Label text='Province' />
                </SimpleItem>
                <SimpleItem dataField='PostalCode' editorType='dxTextBox'>
                  <Label text='Postal Code' />
                  <PatternRule
                    pattern={appConst.postalCodePattern}
                    message={appConst.postalCodeValidationMessage}
                  />
                </SimpleItem>
                <SimpleItem dataField='PreferredPhoneNumber' editorType='dxTextBox' editorOptions={appConst.phoneEditorOptions}>
                  <Label text='Preferred Phone Number' />
                  <PatternRule
                    pattern={appConst.phone10DigitsPattern}
                    message={appConst.phoneEditorOptions.message}
                  />
                  <RequiredRule message="Phone number is required" />
                </SimpleItem>
                <SimpleItem
                  dataField='PreferredPhoneNumberType'
                  editorType='dxSelectBox'
                  editorOptions={phoneNumberTypeEditorOptions}
                >
                  <Label text='Preferred Phone Number Type' />
                </SimpleItem>
                <SimpleItem dataField='OtherPhoneNumber' editorType='dxTextBox' editorOptions={appConst.phoneEditorOptions}>
                  <Label text='Other Phone Number' />
                  <PatternRule
                    pattern={appConst.phone10DigitsPattern}
                    message={appConst.phoneEditorOptions.message}
                  />
                </SimpleItem>
                <SimpleItem
                  dataField='OtherPhoneNumberType'
                  editorType='dxSelectBox'
                  editorOptions={phoneNumberTypeEditorOptions}
                >
                  <Label text='Other Phone Number Type' />
                </SimpleItem>
                <SimpleItem dataField='PersonalEmailAddress' editorType='dxTextBox'>
                  <Label text='Personal Email Address' />
                  <PatternRule
                    pattern={appConst.emailPattern}
                    message={appConst.emailValidationMessage}
                  />
                  <RequiredRule message="Personal email is required" />
                </SimpleItem>
                <SimpleItem dataField='WorkEmailAddress' editorType='dxTextBox'>
                  <Label text='Work Email Address' />
                  <PatternRule
                    pattern={appConst.emailPatternEpsbDomain}
                    message={appConst.emailValidationMessageEpsbDomain}
                  />
                  <RequiredRule message="Work email is required" />
                </SimpleItem>
                <SimpleItem>
                  <div className='title'>
                    <p>Employment Information</p>
                  </div>
                </SimpleItem>
                <SimpleItem dataField='TeachingCertificate' editorType='dxTextBox'>
                  <Label text='Teaching Certificate Number (if known)' />
                </SimpleItem>
                <SimpleItem
                  dataField='CommencementDate'
                  editorType='dxDateBox'
                  editorOptions={{ ...appConst.defaultSelectDateBoxOptions }}
                >
                  <Label text='Commencement of Employment with Edmonton Public Schools' />
                </SimpleItem>
                <SimpleItem
                  render={() => <p>Please specify the school(s) at which you work.</p>}
                />
                {formData.Schools && formData.Schools.map((school, index) => (
                  <GroupItem key={`SchoolGroup${index + 1}`}>
                    <SimpleItem
                      key={`SchoolId${index}`}
                      editorType="dxSelectBox"
                      dataField={`Schools[${index}].SchoolId`}
                      editorOptions={schoolEditorOptions}
                    >
                      <Label text={(index === 0) ? 'School / Decision Unit Where You Primarily Work' : 'Additional School'} />
                    </SimpleItem>
                    <GroupItem key={`DivisionGroup${index + 1}`} colCount={10}>
                      <SimpleItem
                        colSpan={(index === 0) ? 10 : 9}
                        key={`DivisionId${index}`}
                        editorType="dxSelectBox"
                        dataField={`Schools[${index}].DivisionId`}
                        editorOptions={divisionEditorOptions}
                      >
                        <Label text={(index === 0) ? 'Division In Which You Primarily Work at this Location' : 'Additional Division'} />
                      </SimpleItem>
                      <SimpleItem
                        visible={(index > 0)}
                        colSpan={1}
                        itemType="button"
                        horizontalAlignment="left"
                        verticalAlignment="bottom"
                        buttonOptions={{
                          icon: 'trash',
                          hint: 'Remove current school and division',
                          onClick: () => {
                            let sv = ScrollView.getInstance(document.getElementById("singleCardScrollView"));
                            let tmp = { ...formData };
                            tmp.Schools.splice(index, 1);
                            setFormData(tmp);
                            sv.scrollTo(document.getElementById("requestMembershipDataForm").scrollHeight);
                          }
                        }} />
                    </GroupItem>
                    <SimpleItem>
                      <div className="delineate-container">
                        <hr />
                      </div>
                    </SimpleItem>
                  </GroupItem>
                ))}
                <SimpleItem
                  itemType="button"
                  horizontalAlignment="center"
                  buttonOptions={{
                    icon: 'add',
                    text: 'Add Another School',
                    onClick: (e) => {
                      let sv = ScrollView.getInstance(document.getElementById("singleCardScrollView"));
                      let tmp = { ...formData };
                      tmp.Schools.push({ SchoolId: 0, DivisionId: 0 });
                      setFormData(tmp);
                      sv.scrollTo(document.getElementById("requestMembershipDataForm").scrollHeight);
                    }
                  }}
                >
                  <Label text="Please add the school(s) you work at." />
                </SimpleItem>
                <SimpleItem dataField='Inducted' editorType='dxSwitch' editorOptions={{ switchedOnText: 'Yes', switchedOffText: 'No', }}>
                  <Label text='Have you previously been inducted into the ATA through another Local?' />
                </SimpleItem>
                <SimpleItem
                  dataField='Contacted'
                  editorType='dxSwitch'
                  editorOptions={{
                    value: true,
                    switchedOnText: 'Yes',
                    switchedOffText: 'No',
                  }}
                >
                  <Label text='Do you want to be contacted by the Local and/or ATA through your personal email address?' />
                </SimpleItem>
                <SimpleItem
                  itemType="button"
                  horizontalAlignment="left"
                  buttonOptions={{
                    disabled: (submitBtnText === 'Submitting...'),
                    className: 'botton',
                    width: '100%',
                    text: submitBtnText,
                    type: 'default',
                    useSubmitBehavior: 'true',
                  }}
                />
              </GroupItem>
            </Form>
          </form>
        </>
      )
    }
  }

  function renderInformationPosted() {
    if (emailSent) {
      return (
        <>
          <div className='title'>
            Thanks!
          </div>
          <p id="submitMessage">The staff at the the Local office have been notified of your request to create a new membership record. Someone will reach out to you by email or phone when they have reviewed your request.</p>
        </>
      );
    } else {
      return <></>
    }
  }
  return (
    <div className="responsive-paddings">
      {renderFillOutInformation()}
      {renderInformationPosted()}
    </div>
  );
}
